/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { IM, IMLayout, openTeamViewer, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication, useInAppUpdates } from '@infominds/react-native-license'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import prettyBytes from 'pretty-bytes'
import React, { useCallback, useMemo, useState } from 'react'
import { Image, Platform, ScrollView, StyleSheet } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import MicrosoftLogo from '../../assets/img/MicrosoftLogo.svg'
import UserCard from '../../cards/settings/UserCard'
import useRequest from '../../components/Infominds/hooks/useRequest'
import Pressable from '../../components/Infominds/Pressable'
import PressableTextIcon from '../../components/Infominds/PressableTextIcon'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import CompanySelector from '../../components/settings/CompanySelector'
import LanguageSelector, { dropdownStyle } from '../../components/settings/LanguageSelector'
import SettingsRow from '../../components/settings/SettingsRow'
import SettingsRowContainer from '../../components/settings/SettingsRowContainer'
import Switch from '../../components/Switch'
import CONSTANTS from '../../constants/Constants'
import useIsOnline from '../../dataProvider/hooks/useIsOnline'
import useSynchronization from '../../dataProvider/hooks/useSynchronization'
import { ThemeColorExpanded } from '../../types'
import { apiVersionAtom, revalidateSettingsAtom, saveMediaOnStorageAtom } from '../../utils/stateManager'

export default function SettingsView() {
  const navigation = useNavigation()
  const { i18n, language } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const { updateAvailable, prompt: promptUpdate, check } = useInAppUpdates(false, language)

  const { company, companies, customerCode, license, loginType, url, sessionKey, name } = useAuthentication()
  const isOnline = useIsOnline()
  const alert = useAlert()

  const apiVersion = useRecoilValue(apiVersionAtom)
  const [containerHeight, setContainerHeight] = useState(0)
  const [scrollViewHeight, setScrollViewHeight] = useState(0)
  const revalidateSettings = useSetRecoilState(revalidateSettingsAtom)
  const [saveMediaOnStorage, setSaveMediaOnStorage] = useRecoilState(saveMediaOnStorageAtom(sessionKey))

  const sync = useSynchronization()
  const { request: deleteOfflineData, loading: busyDeletingOfflineData } = useRequest(sync.deleteLocalData)
  const [offlineDataSize, setOfflineDataSize] = useState(0)
  const offlineDataFormatted = useMemo(() => prettyBytes(offlineDataSize ?? 0, { locale: language }), [offlineDataSize, language])

  function loadOfflineDataSize() {
    sync.getOfflineDataSize().then(setOfflineDataSize).catch(console.error)
  }

  useFocusEffect(
    useCallback(() => {
      check()
      loadOfflineDataSize()
    }, [])
  )

  const resetApp = () => {
    navigation.reset({
      routes: [
        {
          name: 'BottomTab',
          state: {
            routes: [{ name: 'SettingsStack', state: { routes: [{ name: 'Setting' }] } }],
          },
        },
      ],
    })

    revalidateSettings(true)
    Platform.OS === 'web' && window.location.reload() // Force backend token revalidation
  }

  function handleDeleteOfflineData() {
    alert.alert(i18n.t('WARNING'), i18n.t('SYNC_DELETE_LOCAL_DATA_ALERT'), [
      {
        text: i18n.t('DELETE'),
        style: 'destructive',
        onPress: () => {
          deleteOfflineData({})
        },
      },
      { text: i18n.t('CANCEL'), style: 'cancel', isPreferred: true },
    ])
  }

  const scrollEnabled = scrollViewHeight > containerHeight

  return (
    <IM.View style={IMLayout.flex.f1} onLayout={e => setContainerHeight(e.nativeEvent.layout.height)} onStartShouldSetResponder={() => false}>
      <ScrollView scrollEnabled={scrollEnabled} onContentSizeChange={(_w, h) => setScrollViewHeight(h)}>
        <IM.View spacing="all">
          <UserCard />

          {updateAvailable && (
            <IM.View style={styles.space} spacing="horizontal" spacingType="margin">
              <IM.View style={IMLayout.flex.f2}>
                <PressableTextIcon
                  icon={['fas', 'triangle-exclamation']}
                  color={theme.general.error}
                  onPress={() => promptUpdate()}
                  // eslint-disable-next-line react-native/no-inline-styles
                  viewStyle={{ justifyContent: 'center' }}>
                  {i18n.t('UPDATE_AVAILABLE')}
                </PressableTextIcon>
              </IM.View>
            </IM.View>
          )}

          <SettingsRowContainer title={i18n.t('APP')} spacing="vertical">
            <SettingsRow title={i18n.t('SETTINGS_THEME')}>
              <IM.ThemeSelector containerStyle={dropdownStyle.noShadow} style={dropdownStyle.dropdown} />
            </SettingsRow>

            <SettingsRow title={i18n.t('SETTINGS_LANGUAGE')} style={styles.dropdownRow}>
              <LanguageSelector onReLogIn={resetApp} offline={!isOnline} />
            </SettingsRow>

            <SettingsRow title={i18n.t('SETTINGS_MANDANT')} style={styles.dropdownRow}>
              <CompanySelector onReLogIn={resetApp} offline={!isOnline} />
            </SettingsRow>

            {Platform.OS !== 'web' && (
              <SettingsRow title={i18n.t('SETTINGS_SAVE_MEDIA')}>
                <Switch onValueChange={setSaveMediaOnStorage} value={saveMediaOnStorage} />
              </SettingsRow>
            )}

            {sync.enabled && (
              <SettingsRow title={i18n.t('OFFLINE_DATA')}>
                <IM.View style={[styles.row, styles.pressable]}>
                  <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
                    <IM.Text secondary>{`(${offlineDataFormatted})`}</IM.Text>
                  </IM.View>
                  <Pressable
                    disabled={!!busyDeletingOfflineData}
                    onPress={() => {
                      handleDeleteOfflineData()
                    }}>
                    <TextWithIcon secondary alignIcon="right" icon={['fal', 'chevron-right']} color={theme.text.detail}>
                      {busyDeletingOfflineData ? i18n.t('DELETING') : i18n.t('DELETE')}
                    </TextWithIcon>
                  </Pressable>
                </IM.View>
              </SettingsRow>
            )}

            <SettingsRow title={i18n.t('SETTINGS_SUPPORT')}>
              <Pressable
                onPress={() => {
                  openTeamViewer().catch(err => console.error('Open teamViewer error', err))
                }}
                style={styles.pressable}>
                <TextWithIcon secondary alignIcon="right" icon={['fal', 'chevron-right']} color={theme.text.detail}>
                  {i18n.t('OPEN')}
                </TextWithIcon>
              </Pressable>
            </SettingsRow>
          </SettingsRowContainer>

          <SettingsRowContainer title={i18n.t('INFO')} spacing="vertical">
            <SettingsRow title={i18n.t('SETTINGS_VERSION')} compact>
              <IM.Text secondary style={styles.text} selectable>
                {Platform.OS === 'web' ? CONSTANTS.appName : DeviceInfo.getApplicationName()} v
                {Platform.OS === 'web' ? process.env.VERSION : DeviceInfo.getVersion()}
                {(Platform.OS === 'android' || Platform.OS === 'ios') && ` (${DeviceInfo.getBuildNumber()})`}
              </IM.Text>
            </SettingsRow>

            {isOnline && (
              <SettingsRow title={i18n.t('SETTINGS_API_VERSION')} compact>
                <IM.Text secondary style={styles.text} selectable>
                  v{apiVersion}
                </IM.Text>
              </SettingsRow>
            )}

            <SettingsRow title={i18n.t('SETTINGS_LICENSE')} compact>
              <IM.View style={[IMLayout.flex.f4, styles.alignEnd, IMLayout.flex.row, styles.logoContainer]}>
                <IM.Text secondary style={styles.text} selectable>
                  {license}
                </IM.Text>
                {loginType === 'AAD' ? (
                  <IM.View style={styles.microsoftLogo}>
                    <MicrosoftLogo />
                  </IM.View>
                ) : (
                  <>
                    {colorScheme === 'light' ? (
                      <Image source={require('../../assets/img/InfomindsLogoLight.png')} style={styles.infomindsLogo} />
                    ) : (
                      <Image source={require('../../assets/img/InfomindsLogoDark.png')} style={styles.infomindsLogo} />
                    )}
                  </>
                )}
              </IM.View>
            </SettingsRow>

            <SettingsRow title={i18n.t('SETTINGS_USERNAME')} compact>
              <IM.View style={[IMLayout.flex.f4, styles.alignEnd]}>
                <IM.Text secondary style={styles.text} selectable>
                  {name}
                </IM.Text>
              </IM.View>
            </SettingsRow>

            <SettingsRow title={i18n.t('SETTINGS_SERVER')} compact>
              <IM.Text secondary style={styles.text} selectable>
                {url}
              </IM.Text>
            </SettingsRow>

            {__DEV__ && (
              <>
                <SettingsRow title="Customer code" compact>
                  <IM.Text secondary style={styles.text} selectable>
                    {customerCode}
                  </IM.Text>
                </SettingsRow>

                <SettingsRow title="Company code" compact>
                  <IM.Text secondary style={styles.text} selectable>
                    {company}
                  </IM.Text>
                </SettingsRow>

                <SettingsRow title="Allowed company codes" compact>
                  <IM.Text secondary style={styles.text} selectable>
                    {companies?.join(' - ')}
                  </IM.Text>
                </SettingsRow>
              </>
            )}
          </SettingsRowContainer>
        </IM.View>
      </ScrollView>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  dropdownRow: { zIndex: 1 },
  row: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  space: { flexDirection: 'row', justifyContent: 'space-between', paddingTop: 4 },
  text: { textAlign: 'right' },
  alignEnd: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  microsoftLogo: { width: 12, aspectRatio: 1, marginLeft: 4 },
  infomindsLogo: { width: 16, aspectRatio: 1, marginLeft: 4 },
  logoContainer: { justifyContent: 'flex-end', alignItems: 'center' },
  pressable: { marginRight: -6 },
})
