import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useEffect, useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import api from '../../../apis/apiCalls'
import Error from '../../../components/Error'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import TextWithIcon from '../../../components/Infominds/TextWithIcon'
import { ScreenCardsHeaderShadows } from '../../../components/screen/constants/constants'
import SkeletonText from '../../../components/skeleton/SkeletonText'
import Text from '../../../components/Text'
import TicketActivityClose from '../../../components/TicketActivityClose'
import { closeActivityStyles } from '../../../constants/Constants'
import { REQUEST_TICKET_ACTIVITY } from '../../../constants/Keys'
import useCloseActivity from '../../../hooks/useCloseActivity'
import useUserSettings from '../../../hooks/useUserSettings'
import { ThemeColorExpanded } from '../../../types'
import { CloseActivityViewProps } from '../../activity/ActivityCloseView'
import TicketCloseActivityStep3View from './TicketCloseActivityStep3View'

const TicketCloseActivityStep2View = memo(function TicketCloseActivityStep2View({ ...others }: CloseActivityViewProps) {
  const { i18n } = useLanguage()
  const { closeType } = useCloseActivity()
  const { theme } = useTheme<ThemeColorExpanded>()

  const { userSettings } = useUserSettings()
  const { item: activities, loadItem: getActivities, loading } = useControlledLoader(api.getTicketActivity, { id: REQUEST_TICKET_ACTIVITY })

  const multipleClosing = useMemo(
    () => !!(activities && activities?.length > 1 && userSettings?.allowMultipleCompletionActivity),
    [userSettings, activities]
  )

  useEffect(() => {
    refresh()
  }, [userSettings])

  const refresh = () =>
    userSettings &&
    getActivities(
      userSettings.allowMultipleCompletionActivity
        ? {
            shippingAddressId: userSettings.considerationDeliveryaddressOnMultipleCompletionActivity ? others.shippingAddressId : undefined,
            customerId: others.customerId,
            state: 'Editing',
            employeeId: userSettings.employeeId,
          }
        : {
            id: others.activityId,
          }
    )

  return (
    <>
      <IM.View spacing="top" />
      <IM.View
        style={[
          closeActivityStyles.header,
          ScreenCardsHeaderShadows,
          styles.container,
          {
            backgroundColor: theme.closing.second,
          },
        ]}>
        <Text style={{ fontSize: IMStyle.typography.fontSizeMedium }}>{i18n.t('STEP_2')}</Text>
        {closeType === undefined ? (
          <TextWithIcon icon={['fas', 'circle-exclamation']} color={theme.general.error} viewStyle={styles.error} spacing="bottom">
            {i18n.t('COMPLETE_STEP_1')}
          </TextWithIcon>
        ) : (
          <>
            {multipleClosing ? (
              <Text secondary>{i18n.t('STEP_2_DESCRIPTION_MULTIPLE_CLOSING')}</Text>
            ) : (
              <Text secondary>{i18n.t('STEP_2_DESCRIPTION_SINGLE_CLOSING')}</Text>
            )}
            <IM.View spacing="vertical">
              {loading === false ? (
                <>{activities?.map(activity => <TicketActivityClose key={activity.id} activity={activity} multipleClosing={multipleClosing} />)}</>
              ) : (
                <>{loading === 'catched' ? <Error /> : <SkeletonText width="100%" />}</>
              )}
            </IM.View>
          </>
        )}
        <TicketCloseActivityStep3View {...others} />
      </IM.View>
    </>
  )
})

export default TicketCloseActivityStep2View

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      native: {
        height: '100%',
      },
      web: {
        flexGrow: 1,
      },
    }),
    zIndex: 2,
  },
  error: {
    marginTop: 3,
  },
})
