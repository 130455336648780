/* eslint-disable react-native/no-inline-styles */

import { IM, IMLayout, IMStyle, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, StyleSheet, useWindowDimensions } from 'react-native'
import CountryFlag from 'react-native-country-flag'

import Pressable from '../components/Infominds/Pressable'
import TextInput from '../components/input/TextInput'
import { ThemeColorExpanded } from '../types'
import AvoidingViewModal from './AvoidingViewModal'

export type EditNoteModalType = { value: string | undefined; title: string; id?: string; flag?: string }

interface Props {
  controller: ModalController<EditNoteModalType>
  onClose: (newValue: string | undefined, id: string | undefined) => void
}

export default function EditNoteModal({ controller, onClose }: Props) {
  const { i18n } = useLanguage()
  const { height } = useWindowDimensions()
  const { theme } = useTheme<ThemeColorExpanded>()

  const initial = useRef(controller.data?.value)
  const [text, setText] = useState(controller.data?.value)

  useEffect(() => {
    if (controller.isShown) {
      initial.current = controller.data?.value
      setText(controller.data?.value)
    }
  }, [controller.isShown])

  const disabled = initial.current === text

  if (!controller.data) return <></>

  return (
    <AvoidingViewModal
      controller={controller}
      width={10e6}
      title={
        <>
          <IM.Text style={styles.title}>{controller.data.title}</IM.Text>
          {controller.data.flag && <CountryFlag isoCode={controller.data?.flag} size={20} />}
        </>
      }
      titleViewStyle={[IMLayout.flex.row, { justifyContent: 'space-between' }]}>
      <TextInput
        value={text ?? ''}
        onChangeText={val => setText(val === '' ? undefined : val)}
        multiline
        fixMultilineHeight
        numberOfLines={Platform.OS === 'web' ? 30 : 11}
        style={{ maxHeight: height * 0.7 }}
      />
      <IM.View style={styles.buttonsContainer} spacing="top">
        <Pressable spacing="right" onPress={controller.close}>
          <IM.Text style={styles.text}>{i18n.t('CANCEL').toUpperCase()}</IM.Text>
        </Pressable>
        <Pressable
          disabled={disabled}
          onPress={() => {
            controller.close()
            onClose(text, controller.data?.id)
          }}>
          <IM.Text secondary={disabled} style={[styles.text, !disabled && { color: theme.general.info }]}>
            {i18n.t('SAVE').toUpperCase()}
          </IM.Text>
        </Pressable>
      </IM.View>
    </AvoidingViewModal>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: IMStyle.typography.fontSizeMedium + 2,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  text: {
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
