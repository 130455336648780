import { useContext } from 'react'

import { PendingSignatureContext } from '../contexts/PendingSignatureContext'

export default function usePendingSignature() {
  const context = useContext(PendingSignatureContext)

  if (context === undefined) {
    throw new Error('usePendingSignature() must be called inside PendingSignatureProvider')
  }

  return context
}
