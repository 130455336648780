import { IMLayout, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'
import React, { createRef, memo, useEffect, useMemo, useState } from 'react'
import { Animated, Keyboard, Platform } from 'react-native'

import api from '../../apis/apiCalls'
import QualityCheckCard from '../../cards/common/QualityCheckCard'
import DoneButton from '../../components/DoneButton'
import FlashListData from '../../components/FlashListData'
import FormButton from '../../components/FormButton'
import KeyboardAware from '../../components/KeyboardAware'
import useSearch from '../../components/screen/hooks/useSearch'
import { QUALITY_DONE_BUTTON_ID } from '../../constants/ButtonIds'
import { REFRESH_ACTIVITY_QUALITY_CHECK_EVENT_KEY } from '../../constants/EmitterKeys'
import { QualityCheckGroupType, QualityCheckOrderType } from '../../contexts/QualityCheckFilterContext'
import useQualityCheck from '../../hooks/useQualityCheck'
import useQualityCheckFilter from '../../hooks/useQualityCheckFilter'
import { CharacteristicsModified, LoadingType, ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import qualityUtils from '../../utils/QualityUtils'
import ticketUtils from '../../utils/TicketUtils'

const buttonAnimationValue = new Animated.Value(0)

interface Props {
  loading: LoadingType
  characteristics: CharacteristicsModified[]
  onRefresh: () => void
}

export default function QualityCheckView({ characteristics, loading, onRefresh }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { search } = useSearch()
  const { groups, orders } = useQualityCheckFilter()
  const { enableDone, qualityValues, qualityNotes, changeInitials } = useQualityCheck()

  const [pressed, setPressed] = useState(false)
  const listRef = createRef<FlashList<string | CharacteristicsModified>>()

  const { emit } = useEvent({ key: REFRESH_ACTIVITY_QUALITY_CHECK_EVENT_KEY })

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })
  }, [groups, orders])

  const handlePress = () => {
    setPressed(true)

    const qualityToCreate = qualityUtils.prepareQualityCheck(characteristics ?? [], qualityValues, qualityNotes, 'POST')
    const qualityToUpdate = qualityUtils.prepareQualityCheck(characteristics ?? [], qualityValues, qualityNotes, 'PATCH')

    const promise: Promise<unknown>[] = []

    qualityToCreate.forEach(el => {
      promise.push(api.createQuality(el))
    })

    qualityToUpdate.forEach(el => {
      promise.push(api.editQuality(el))
    })

    Promise.all(promise).catch(console.error).finally(handleEnd)

    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })

    if (promise.length === 0) {
      handleEnd()
    }
  }

  const handleEnd = () => {
    emit()
    setPressed(false)
    changeInitials(qualityValues, qualityNotes)
    onRefresh()
  }

  const filtered: (CharacteristicsModified | string)[] = useMemo(() => {
    if (loading) return []

    let charClone = cloneDeep(characteristics)

    // Search
    charClone = charClone ? appUtils.filter(charClone, search, ['qualityCharacteristicDescription', 'serialnumber']) : []

    // Grouping
    let grouping: 'Serial' | 'Attribute' | undefined
    let charGrouped: { [key: string]: CharacteristicsModified[] } = { undefined: charClone }
    const charGroups = groups.find(group => group.active)
    switch (charGroups?.data.id) {
      case QualityCheckGroupType.SerialNumber: {
        grouping = 'Serial'
        charGrouped = groupBy(
          charClone,
          el => el.serialnumber + (el.serialnumberManufacturer ? ` (${el.serialnumberManufacturer})` : '') + '\n' + el.articleDescription
        )
        charGrouped = ticketUtils.replaceUndefinedSection(charGrouped, i18n.t('NO_SERIAL_NUMBER'))

        break
      }
      case QualityCheckGroupType.Attribute: {
        grouping = 'Attribute'
        charGrouped = groupBy(charClone, el => el.qualityCharacteristicDescription)
        charGrouped = ticketUtils.replaceUndefinedSection(charGrouped, i18n.t('NO_DESCRIPTION'))

        break
      }
    }

    const charOrder = orders.find(order => order.active)
    switch (charOrder?.data.id) {
      case QualityCheckOrderType.AlphabeticalAscending: {
        switch (grouping) {
          case undefined:
          case 'Serial': {
            Object.keys(charGrouped).forEach(key => {
              charGrouped[key] = charGrouped[key].sort((a, b) => a.qualityCharacteristicDescription.localeCompare(b.qualityCharacteristicDescription))
            })
            break
          }
          case 'Attribute': {
            // Done separately
            break
          }
        }

        break
      }
      case QualityCheckOrderType.AlphabeticalDescending: {
        switch (grouping) {
          case undefined:
          case 'Serial': {
            Object.keys(charGrouped).forEach(key => {
              charGrouped[key] = charGrouped[key].sort((a, b) => b.qualityCharacteristicDescription.localeCompare(a.qualityCharacteristicDescription))
            })
            break
          }
          case 'Attribute': {
            // Done separately
            break
          }
        }

        break
      }
      case QualityCheckOrderType.SerialNumberSortKeyAscending: {
        switch (grouping) {
          case undefined: {
            Object.keys(charGrouped).forEach(key => {
              charGrouped[key] = charGrouped[key].sort((a, b) => a.serialnumberSortkey - b.serialnumberSortkey)
            })
            break
          }
          case 'Serial': {
            // Done separately
            break
          }
          case 'Attribute': {
            Object.keys(charGrouped).forEach(key => {
              charGrouped[key] = charGrouped[key].sort((a, b) => a.serialnumberSortkey - b.serialnumberSortkey)
            })
            break
          }
        }

        break
      }
      case QualityCheckOrderType.SerialNumberSortKeyDescending: {
        switch (grouping) {
          case undefined: {
            Object.keys(charGrouped).forEach(key => {
              charGrouped[key] = charGrouped[key].sort((a, b) => b.serialnumberSortkey - a.serialnumberSortkey)
            })
            break
          }
          case 'Serial': {
            // Done separately
            break
          }
          case 'Attribute': {
            Object.keys(charGrouped).forEach(key => {
              charGrouped[key] = charGrouped[key].sort((a, b) => b.serialnumberSortkey - a.serialnumberSortkey)
            })
            break
          }
        }

        break
      }
    }

    const data: (string | CharacteristicsModified)[] = []
    const keys = Object.keys(charGrouped)

    if (grouping === 'Attribute') {
      if (charOrder?.data.id === QualityCheckOrderType.AlphabeticalDescending) {
        keys.sort((a, b) => charGrouped[b][0].qualityCharacteristicDescription.localeCompare(charGrouped[a][0].qualityCharacteristicDescription))
      }

      if (charOrder?.data.id === QualityCheckOrderType.AlphabeticalAscending) {
        keys.sort((a, b) => charGrouped[a][0].qualityCharacteristicDescription.localeCompare(charGrouped[b][0].qualityCharacteristicDescription))
      }
    }

    if (grouping === 'Serial') {
      if (charOrder?.data.id === QualityCheckOrderType.SerialNumberSortKeyAscending) {
        keys.sort((a, b) => charGrouped[b][0].serialnumberSortkey - charGrouped[a][0].serialnumberSortkey)
      }

      if (charOrder?.data.id === QualityCheckOrderType.SerialNumberSortKeyDescending) {
        keys.sort((a, b) => charGrouped[a][0].serialnumberSortkey - charGrouped[b][0].serialnumberSortkey)
      }
    }

    keys.forEach(key => {
      if (charGrouped[key].length !== 0) {
        if (key !== 'undefined') {
          data.push(key)
          data.push(...charGrouped[key])
        } else {
          data.push(...charGrouped[key])
        }
      }
    })

    return data
  }, [groups, orders, characteristics, loading, search])

  const activeSnGrouping = useMemo(() => groups.find(group => group.active)?.data.id === QualityCheckGroupType.SerialNumber, [groups])

  return (
    <>
      <KeyboardAware>
        <FlashListData
          ref={listRef}
          data={filtered}
          loading={loading}
          renderItem={elem => <RenderItem item={elem.item} index={elem.index} target={elem.target} hideSerialNumber={!activeSnGrouping} />}
          closeEndThreshold={40}
          noDataMessage={i18n.t('NO_QUALITY_CHECK_FOUND')}
          hideButtonId={QUALITY_DONE_BUTTON_ID}
          refresh={filtered.length === 0 ? onRefresh : undefined}
          keyboardShouldPersistTaps={Platform.OS !== 'web' && Keyboard.isVisible() ? 'always' : 'never'}
          keyExtractor={el => (typeof el === 'string' ? el : `${el.id}-${el.serialnumberId}-${el.qualityCharacteristicId}`)}
          footer={
            <FormButton
              icon={['fal', 'check']}
              title={i18n.t('SAVE')}
              onPress={handlePress}
              disabled={pressed || !enableDone || loading !== false}
              color={theme.general.info}
              spacing="all"
            />
          }
        />
      </KeyboardAware>
      <DoneButton
        id={QUALITY_DONE_BUTTON_ID}
        value={buttonAnimationValue}
        disabled={!enableDone || loading !== false}
        loading={loading || pressed ? 'reloading' : false}
        text={i18n.t('SAVE')}
        onPress={handlePress}
      />
    </>
  )
}

const RenderItem = memo((elem: ListRenderItemInfo<CharacteristicsModified | string> & { hideSerialNumber: boolean }) => {
  const item = elem.item

  if (typeof item === 'string') return <></>

  return (
    <QualityCheckCard
      characteristic={item}
      hideSerialNumber={elem.hideSerialNumber}
      style={[
        {
          marginTop: IMLayout.horizontalMargin,
          marginHorizontal: 2 * IMLayout.horizontalMargin,
        },
      ]}
    />
  )
})
