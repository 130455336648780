import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'
import CountryFlag from 'react-native-country-flag'

import { ThemeColorExpanded } from '../../types'
import PressableTextIcon from '../Infominds/PressableTextIcon'

type Props = {
  value: string
  title: string
  editable?: boolean
  flagCode?: string
  onPress: () => void
}

export function DescriptionInput({ editable = true, onPress, title, value, flagCode }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View spacing="bottom">
      <IM.View style={[IMLayout.flex.row, styles.center]}>
        <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{title}</IM.Text>
        <PressableTextIcon
          icon={['fal', 'pen']}
          alignIcon="right"
          iconSize={12}
          color={editable ? theme.text.default : theme.text.detail}
          iconColor={editable ? theme.text.default : theme.text.detail}
          disabled={!editable}
          onPress={onPress}>
          {i18n.t('EDIT')}
        </PressableTextIcon>
      </IM.View>
      <IM.View style={[styles.content, { backgroundColor: theme.inputBox.background.disabled }]}>
        <IM.Text>{value}</IM.Text>
        {flagCode && <CountryFlag isoCode={flagCode} size={20} />}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  center: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    borderRadius: 10,
    padding: 8,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 38,
  },
})
