import { IMLayout, useDimensions, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { Pressable } from 'react-native'

import useLayout from '../hooks/useLayout'
import TextWithIcon from './Infominds/TextWithIcon'

interface Props {
  note: string | undefined
  onPress: () => void
}

function QualityCheckCardNote({ note, onPress }: Props) {
  const { theme } = useTheme()
  const { isSmallDevice } = useDimensions()
  const { isPortrait } = useLayout()
  const forceLarge = !isSmallDevice && !isPortrait

  return (
    <>
      {note !== undefined && note !== '' && (
        <Pressable
          // eslint-disable-next-line react-native/no-inline-styles
          style={[IMLayout.flex.f6, { marginTop: 6, marginRight: forceLarge ? 8 : 0 }]}
          onPress={onPress}
          hitSlop={{ top: 24, bottom: 10 }}>
          <TextWithIcon
            icon={['fal', 'note-sticky']}
            secondary={!!note}
            style={[IMLayout.flex.f1, { color: note ? theme.text.detail : theme.text.placeholder }]}
            iconColor={theme.text.detail}>
            {note}
          </TextWithIcon>
        </Pressable>
      )}
    </>
  )
}

export default memo(QualityCheckCardNote)
