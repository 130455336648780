import { ApiClient } from '@infominds/react-api'

import { DataProvider } from '../dataProvider/DataProvider'
import { CharacteristicsModified, MenuItems, SMNoteType, SynchronisationData, SynchronisationInfo } from '../types'
import { OfflineConfig } from './offline/OfflineConfig'
import {
  CreateAccessDataRequest,
  GetAccessDataLevel,
  GetAccessDataRequest,
  GetActivityEmailRequest,
  GetActivityRequest,
  GetActivitySparePartsPriceRequest,
  GetActivitySparePartsRequest,
  GetActivityStateRequest,
  GetActivityTimeRequest,
  GetActivityTypeRequest,
  GetAdditionalFieldsDefinitionRequest,
  GetAdditionalFieldsDefinitionValueRequest,
  GetAdditionalFieldsRequest,
  GetAdditionalFieldsRequestTickerOrActivity,
  GetArticleRequest,
  GetArticleSparePartsRequest,
  GetClassificationDefinitionValueRequest,
  GetContactDataRequest,
  GetCustomersRequest,
  GetDestinationRequest,
  GetEmployeeRequest,
  GetGroupsRequest,
  GetInfoboxFilesRequest,
  GetInfoboxFolderWithCountRequest,
  GetMessageRequest,
  GetNoteGroupRequest,
  GetNoteRequest,
  GetOtpRequest,
  GetPasswordGenerationRequest,
  GetPasswordRequest,
  GetPlanningTimeRequest,
  GetPurchaseOrderRequest,
  GetQualityRequest,
  GetReportRequest,
  GetSerialNumberAccessoryRequest,
  GetSerialNumberCounterRequest,
  GetSerialNumberNumberRequest,
  GetSerialNumberRequest,
  GetSerialNumberTicketRequest,
  GetSMNoteRequest,
  GetSynchronisationDataRequest,
  GetSynchronisationInfoRequest,
  GetTeamRequest,
  GetTicketActivityRequest,
  GetTicketArticleRequest,
  GetTicketClassificationDefinitionRequest,
  GetTicketClassificationRequest,
  GetTicketListRequest,
  GetTicketRegistryRequest,
  GetTicketStateRequest,
  GetTravelTimeRequest,
  GetVaultAccessRequest,
  InfoboxFile,
  PostPurchaseOrderRequest,
  PostSerialNumberBody,
  PostTicketRequest,
  PutAccessDataPasswordRequest,
  PutAccessDataRequest,
  PutAddFieldsRequest,
  PutClassificationRequest,
  VaultRequest,
} from './types/apiRequestTypes'
import {
  AccessData,
  Activity,
  ActivityEmailResponse,
  ActivitySparePart,
  ActivityTime,
  ActivityTypeGet,
  AdditionalFieldDefinition,
  AdditionalFieldDefinitionValue,
  AdditionalFieldResponse,
  Article,
  ArticleSparePart,
  ClassificationDefinitionValue,
  Company,
  Contact,
  Country,
  Customer,
  Destination,
  EmailType,
  Employee,
  FaxType,
  FolderWithCount,
  Group,
  InvoiceType,
  Language,
  Message,
  Note,
  NoteGroup,
  Otp,
  PasswordGenerationDefaults,
  PatchActivityAllocation,
  PatchTicketArticle,
  PhoneType,
  PlanningTime,
  PostActivityReport,
  PostAdditionalField,
  PostClassification,
  PostTicketArticle,
  PostTicketReport,
  PostTicketResponse,
  PurchaseOrder,
  Quality,
  QualityList,
  RadixProtocol,
  Report,
  SerialNumber,
  SerialNumberAccessory,
  SerialNumberCounter,
  SerialNumberTicket,
  SMNote,
  SparePartDepot,
  StateResponse,
  Team,
  Ticket,
  TicketActivity,
  TicketArticle,
  TicketClassificationDefinition,
  TicketClassificationRequest,
  TicketPriorityResponse,
  TicketRegistry,
  TravelTime,
  UserSettings,
  VaultResponse,
} from './types/apiResponseTypes'

const company = DataProvider<Company>('company')
const customer = DataProvider<Customer, GetCustomersRequest>('customer', OfflineConfig.customer)
const customerDestinations = DataProvider<Destination, GetDestinationRequest>('customer/shippingAddress', OfflineConfig.customerDestinations)
const customerContacts = DataProvider<Contact, GetContactDataRequest>('customer/contact', OfflineConfig.customerContacts)
const ticket = DataProvider<Ticket, GetTicketListRequest>('ticket', OfflineConfig.ticket)
const ticketArticle = DataProvider<TicketArticle, GetTicketArticleRequest>('ticket/article', OfflineConfig.ticketArticle)
const ticketRegistry = DataProvider<TicketRegistry, GetTicketRegistryRequest>('ticket/register')
const ticketState = DataProvider<StateResponse, GetTicketStateRequest>('ticket/states', OfflineConfig.ticketState)
const ticketPriority = DataProvider<TicketPriorityResponse, GetTicketStateRequest>('ticket/priorities', OfflineConfig.ticketPriority)

const ticketClassification = DataProvider<TicketClassificationRequest, GetTicketClassificationRequest>(
  'common/classificationsOfTickets',
  OfflineConfig.ticketClassification
)
const ticketClassificationDefinition = DataProvider<TicketClassificationDefinition, GetTicketClassificationDefinitionRequest>(
  'common/classificationDefinitionTicket'
)
const classificationDefinitionValues = DataProvider<ClassificationDefinitionValue, GetClassificationDefinitionValueRequest>(
  'common/classificationDefinitionValue',
  OfflineConfig.classificationDefinitionValues
)
const addFields = DataProvider<AdditionalFieldResponse, GetAdditionalFieldsRequest>('common/additionalfields')
const ticketAddFields = DataProvider<AdditionalFieldResponse, GetAdditionalFieldsRequestTickerOrActivity>(
  'common/additionalfieldsOfTickets',
  OfflineConfig.additionalfieldsOfTickets
)
const activityAddFields = DataProvider<AdditionalFieldResponse, GetAdditionalFieldsRequestTickerOrActivity>(
  'common/additionalfieldsOfActivities',
  OfflineConfig.additionalfieldsOfActivities
)
const addFieldsDefinitions = DataProvider<AdditionalFieldDefinition, GetAdditionalFieldsDefinitionRequest>(
  'common/additionalfieldDefinition',
  OfflineConfig.addFieldsDefinitions
)
const addFieldsDefinitionValues = DataProvider<AdditionalFieldDefinitionValue, GetAdditionalFieldsDefinitionValueRequest>(
  'common/additionalfieldDefinitionValue',
  OfflineConfig.addFieldsDefinitionValues
)
const activity = DataProvider<Activity, GetActivityRequest>('activity', OfflineConfig.activity, data => {
  const act = data as Activity[]

  const toRet = act.map(el => {
    if (el.ticketArticles?.length) {
      const found = el.ticketArticles.find(art => art.position === 1)

      if (found) {
        el.articleDescription = found.articleDescription
        el.serialnumber = found.serialnumber
        el.serialnumberLocation = found.serialnumberLocation
        el.serialnumberManufacturerNumber = found.serialnumberManufacturerNumber
      }
    }

    return el
  })

  return toRet
})
const activityState = DataProvider<StateResponse, GetActivityStateRequest>('activity/states')
const ticketActivity = DataProvider<TicketActivity, GetTicketActivityRequest>('ticket/activity', OfflineConfig.ticketActivity)
const activityTime = DataProvider<ActivityTime, GetActivityTimeRequest>('activity/time', OfflineConfig.activityTime)
const activitySpareParts = DataProvider<ActivitySparePart, GetActivitySparePartsRequest>('activity/sparepart', OfflineConfig.activitySpareParts)
const sparePartDepot = DataProvider<SparePartDepot>('activity/sparepartdeposit', OfflineConfig.sparePartDepot)
const activityEmail = DataProvider<ActivityEmailResponse, GetActivityEmailRequest>('activity/emails')
const planningTime = DataProvider<PlanningTime, GetPlanningTimeRequest>('employee/planningtime')
const activityType = DataProvider<ActivityTypeGet, GetActivityTypeRequest>('activity/activitytypes')
const serialNumbers = DataProvider<SerialNumber, GetSerialNumberRequest>('serialnumber')
const serialNumberCounter = DataProvider<SerialNumberCounter, GetSerialNumberCounterRequest>(
  'serialnumber/counter',
  OfflineConfig.serialNumberCounter
)
const serialNumberAccessories = DataProvider<SerialNumberAccessory, GetSerialNumberAccessoryRequest>('serialnumber/details')
const serialNumberTickets = DataProvider<SerialNumberTicket, GetSerialNumberTicketRequest>('serialnumber/tickets')
const userSettings = DataProvider<UserSettings>('userSettings', OfflineConfig.userSettings)
const phoneTypes = DataProvider<PhoneType>('common/phoneTypes', OfflineConfig.phoneTypes)
const emailTypes = DataProvider<EmailType>('common/emailTypes', OfflineConfig.emailTypes)
const faxTypes = DataProvider<FaxType>('common/faxtypes', OfflineConfig.faxTypes)
const invoiceTypes = DataProvider<InvoiceType>('common/invoicetypes', OfflineConfig.invoiceTypes)
const travelTime = DataProvider<TravelTime, GetTravelTimeRequest>('employee/traveltime', OfflineConfig.travelTime)

const note = DataProvider<Note, GetNoteRequest>('common/note')
const noteGroup = DataProvider<NoteGroup, GetNoteGroupRequest>('common/notegroup', OfflineConfig.noteGroup)
const message = DataProvider<Message, GetMessageRequest, Message>('common/servicemanagementcomment')
const messageLastRead = DataProvider<{ fkId: string; type: SMNoteType }, unknown, never, unknown, boolean>(
  'common/serviceManagementCommentSetAllExternalUnreadToRead'
)

const smNote = DataProvider<SMNote, GetSMNoteRequest>('common/servicemanangementnote', OfflineConfig.smNote)
const employee = DataProvider<Employee, GetEmployeeRequest>('employee', OfflineConfig.employee)
const team = DataProvider<Team, GetTeamRequest>('employee/teams')

const languages = DataProvider<Language>('common/languages', OfflineConfig.languages)
const countries = DataProvider<Country>('common/countries', OfflineConfig.countries)

const article = DataProvider<Article, GetArticleRequest>('article')
const articleSpareParts = DataProvider<ArticleSparePart, GetArticleSparePartsRequest>('article/sparepart', OfflineConfig.articleSpareParts)
const report = DataProvider<Report, GetReportRequest>('ticket/reports')
const sendTicketReport = DataProvider<PostTicketReport>('ticket/sendinfo')
const sendActivityReport = DataProvider<PostActivityReport>('activity/sendinfo')

const quality = DataProvider<Quality, GetQualityRequest>('qualityControl', OfflineConfig.quality)
async function getModifiedQuality(request?: GetQualityRequest | undefined, abortController?: AbortController | undefined) {
  const originalValue = await quality.GET(request, abortController)
  const toRet: CharacteristicsModified[] = []
  originalValue.forEach(val => {
    val.characteristics?.forEach(char => {
      toRet.push({
        ...char,
        activityId: val.activityId,
        serialnumber: val.serialnumber,
        serialnumberId: val.serialnumberId,
        serialnumberManufacturer: val.serialnumberManufactor,
        id: val.id,
        articleDescription: val.articleDescription,
        value: char.qualityCharacteristicFieldTyp === 'Boolean' ? char.value?.toLowerCase() : char.value,
        serialnumberSortkey: val.serialnumberSortkey,
      })
    })
  })

  return toRet
}
const qualityList = DataProvider<QualityList>('qualityControl/ticketarticle', OfflineConfig.qualityList)

const menuItems = DataProvider<MenuItems>('menu', OfflineConfig.menuItems)

const synchronisation = DataProvider<SynchronisationInfo, GetSynchronisationInfoRequest>('synchronisation')
const synchronisationData = DataProvider<SynchronisationData, GetSynchronisationDataRequest>('synchronisation/data')
const radixProtocol = DataProvider<RadixProtocol>('common/protocol')

const infoboxFolderWithFileCount = DataProvider<FolderWithCount, GetInfoboxFolderWithCountRequest>(
  'common/infoboxfolderwithfilecount',
  OfflineConfig.infoBoxFolderWithFileCount
)
const infoboxFiles = DataProvider<InfoboxFile, GetInfoboxFilesRequest>('common/infoboxfiles', OfflineConfig.infoboxFiles)
const infoboxFile = DataProvider<InfoboxFile, GetInfoboxFilesRequest>('common/infoboxfile', OfflineConfig.infoboxFile)

export function getApi(client: ApiClient) {
  return {
    getOrder: (request: GetPurchaseOrderRequest, abortController?: AbortController) =>
      client.GET<PurchaseOrder[]>('ticket/purchaseorder', request, undefined, abortController),
    createOrder: (body: PostPurchaseOrderRequest, abortController?: AbortController) =>
      client.POST<string>('ticket/purchaseorder', body, abortController),
    getActivitySparePartsPrice: (request: GetActivitySparePartsPriceRequest, abortController?: AbortController) =>
      client.GET<number>('activity/sparepartprice', request, undefined, abortController),
    editClassification: (body: PutClassificationRequest, abortController?: AbortController) =>
      client.PUT<string>('common/classifications', body, abortController),
    editAddFields: (body: PutAddFieldsRequest, abortController?: AbortController) =>
      client.PUT<string>('common/additionalfields', body, abortController),
    createTicket: (body: PostTicketRequest, abortController?: AbortController) => client.POST<PostTicketResponse>('ticket', body, abortController),
    createTicketClassification: (body: PostClassification, abortController?: AbortController) =>
      client.POST<string>('common/classifications', body, abortController),
    createTicketAdditionalField: (body: PostAdditionalField, abortController?: AbortController) =>
      client.POST<string>('common/additionalfields', body, abortController),
    addTicketArticle: (body: PostTicketArticle, abortController?: AbortController) => client.POST<boolean>('ticket/article', body, abortController),
    editTicketArticle: (body: PatchTicketArticle, abortController?: AbortController) =>
      client.PATCH<boolean>('ticket/article', body, abortController),
    deleteTicketArticle: (body: { id: string }, abortController?: AbortController) =>
      client.DELETE<boolean>('ticket/article', body, undefined, abortController),
    editActivityAllocation: (body: PatchActivityAllocation, abortController?: AbortController) =>
      client.PATCH<boolean>('activity/allocation', body, abortController),

    // Access data -> No data provider required
    getAccessData: (request: GetAccessDataRequest, abortController?: AbortController) =>
      client.GET<VaultResponse<AccessData[]>>('accessdata/list', request, undefined, abortController),
    getAccessDataLevel: (request: GetAccessDataLevel, abortController?: AbortController) =>
      client.GET<VaultResponse<number>>('accessdata/level', request, undefined, abortController),
    getGroups: (request: GetGroupsRequest, abortController?: AbortController) =>
      client.GET<VaultResponse<Group[]>>('accessdata/groups', request, undefined, abortController),
    getPassword: (request: GetPasswordRequest, abortController?: AbortController) =>
      client.GET<VaultResponse<string>>('accessdata/passworddecrypt', request, undefined, abortController),
    getOpt: (request: GetOtpRequest, abortController?: AbortController) =>
      client.GET<VaultResponse<Otp>>('accessdata/otp', request, undefined, abortController),
    getVaultAccess: (request: GetVaultAccessRequest, abortController?: AbortController) =>
      client.GET<boolean>('accessdata/vaultaccess', request, undefined, abortController),
    getPasswordGenerationDefaults: (request: VaultRequest, abortController?: AbortController) =>
      client.GET<VaultResponse<PasswordGenerationDefaults>>('accessdata/generatepassworddefaultvalues', request, undefined, abortController),
    generatePassword: (request: GetPasswordGenerationRequest, abortController?: AbortController) =>
      client.GET<VaultResponse<string>>('accessdata/generatepassword', request, undefined, abortController),
    updateAccessData: (body: PutAccessDataRequest, abortController?: AbortController) =>
      client.PUT<VaultResponse<string>>('accessdata/item', body, abortController),
    createAccessData: (body: CreateAccessDataRequest, abortController?: AbortController) =>
      client.POST<VaultResponse<string>>('accessdata/item', body, abortController),
    deleteAccessData: (body: { vaultSessionId: string; id: string }, abortController?: AbortController) =>
      client.DELETE<VaultResponse<string>>('accessdata', body, undefined, abortController),
    updateAccessDataPassword: (body: PutAccessDataPasswordRequest, abortController?: AbortController) =>
      client.PUT<VaultResponse<string>>('accessdata/itempassword', body, abortController),
    createSerialNumber: (body: PostSerialNumberBody, abortController?: AbortController) => client.POST<string>('serialnumber', body, abortController),
    getSerialNumberNumber: (request: GetSerialNumberNumberRequest, abortController?: AbortController) =>
      client.GET<string>('serialnumber/number', request, undefined, abortController),
  }
}

const api = {
  getTicketActivity: ticketActivity.GET, // with less info than activity.GET

  // Activity
  getActivity: activity.GET,
  createActivity: activity.POST,
  editActivity: activity.PATCH,
  getActivityState: activityState.GET,
  getActivityType: activityType.GET,
  getActivityTime: activityTime.GET,
  createActivityTime: activityTime.POST,
  editActivityTime: activityTime.PATCH,
  deleteActivityTime: activityTime.DELETE,
  getActivityEmail: activityEmail.GET_FIRST,
  getActivitySpareParts: activitySpareParts.GET,
  createActivitySpareParts: activitySpareParts.POST,
  editActivitySpareParts: activitySpareParts.PATCH,
  deleteActivitySpareParts: activitySpareParts.DELETE,
  getSparePartDepot: sparePartDepot.GET,

  // Planning Time
  getPlanningTime: planningTime.GET,
  createPlanningTime: planningTime.POST,
  editPlanningTime: planningTime.PATCH,
  deletePlanningTime: planningTime.DELETE,

  // Article
  getArticle: article.GET,
  getArticleSpareParts: articleSpareParts.GET,

  // Customers
  getCustomer: customer.GET,
  postCustomer: customer.POST,
  editCustomer: customer.PUT,
  // deleteCustomer: customer.DELETE,

  // Travel time
  getTravelTime: travelTime.GET_FIRST,
  stopTravelTime: travelTime.PATCH,
  startTravelTime: travelTime.POST,

  // Quality
  getQuality: getModifiedQuality,
  createQuality: quality.POST,
  editQuality: quality.PATCH,
  deleteQuality: quality.DELETE,
  getQualityList: qualityList.GET,

  // Common
  getLanguages: languages.GET,
  getCountries: countries.GET,
  getTicketClassification: ticketClassification.GET_FIRST,
  getTicketClassificationDefinition: ticketClassificationDefinition.GET,
  editTicketClassification: ticketClassification.PUT,
  getClassificationDefinitionValues: classificationDefinitionValues.GET,
  getAddFields: addFields.GET_FIRST,
  getTicketAddFields: ticketAddFields.GET,
  editTicketAddFields: ticketAddFields.PUT,
  getActivityAddFields: activityAddFields.GET,
  editActivityAddFields: activityAddFields.PUT,

  getAddFieldsDefinitions: addFieldsDefinitions.GET,
  getAddFieldsDefinitionValues: addFieldsDefinitionValues.GET,
  getNotes: note.GET,
  createNote: note.POST,
  deleteNote: note.DELETE,
  getMessages: message.GET,
  sendMessage: message.POST,
  deleteMessage: message.DELETE,
  updateMessageLastRead: messageLastRead.PATCH,
  getSMNotes: smNote.GET,
  createSMNote: smNote.POST,
  editSMNote: smNote.PATCH,
  deleteSMNote: smNote.DELETE,
  getMenuItems: menuItems.GET_FIRST,
  getNoteGroup: noteGroup.GET,
  getPhoneTypes: phoneTypes.GET,
  getEmailTypes: emailTypes.GET,
  getFaxTypes: faxTypes.GET,
  getInvoiceType: invoiceTypes.GET,

  // Customer contact
  getCustomerContacts: customerContacts.GET,
  createCustomerContact: customerContacts.POST,
  editCustomerContact: customerContacts.PUT,
  deleteCustomerContact: customerContacts.DELETE,

  // Customer destinations
  getCustomerDestinations: customerDestinations.GET,
  createCustomerDestinations: customerDestinations.POST,
  editCustomerDestinations: customerDestinations.PUT,
  deleteCustomerDestinations: customerDestinations.DELETE,

  // Employee
  getEmployee: employee.GET,
  getTeam: team.GET,

  // Report
  getReports: report.GET,
  sendTicketReport: sendTicketReport.POST,
  sendActivityReport: sendActivityReport.POST,

  // Infobox
  getInfoboxFoldersWithFileCount: infoboxFolderWithFileCount.GET,
  getInfoboxFiles: infoboxFiles.GET,
  getArticleInfoboxFiles: infoboxFiles.GET,
  getInfoboxFile: infoboxFile.GET_FIRST,
  updateInfoboxFile: infoboxFile.PATCH,
  deleteInfoboxFile: (request: Partial<InfoboxFile>, abortController?: AbortController) => infoboxFile.DELETE(request, abortController, true),
  postInfoboxFile: infoboxFile.POST,

  // Tickets
  getTicket: ticket.GET,
  editTicket: ticket.PATCH,

  getTicketArticle: ticketArticle.GET,
  getTicketState: ticketState.GET,
  getTicketPriority: ticketPriority.GET,
  getTicketRegistry: ticketRegistry.GET,

  // SN
  getSerialNumber: serialNumbers.GET,
  editSerialNumber: serialNumbers.PATCH,
  getSerialNumberCounter: serialNumberCounter.GET,
  editSerialNumberCounter: serialNumberCounter.PATCH,
  getSerialNumberAccessories: serialNumberAccessories.GET,
  getSerialNumberTickets: serialNumberTickets.GET,

  // User settings
  getUserSettings: userSettings.GET_FIRST,

  //company
  getCompanies: company.GET,

  //synchronization
  getSynchronizationInfo: synchronisation.GET_FIRST,
  getSynchronizationData: synchronisationData.GET_FIRST,
  sendProtocol: radixProtocol.POST,
}

export default api
