import { IM, IMLayout, useEvent, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import React, { createRef, memo, useEffect, useMemo, useState } from 'react'
import { Animated } from 'react-native'
import { useRecoilValue } from 'recoil'

import api from '../../apis/apiCalls'
import { Ticket } from '../../apis/types/apiResponseTypes'
import TicketListCard from '../../cards/ticketList/TicketListCard'
import FlashListData from '../../components/FlashListData'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useSearch from '../../components/screen/hooks/useSearch'
import TravelTimeButton from '../../components/TravelTimeButton'
import { ADD_TICKET_BUTTON_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { REFRESH_TICKET_LIST_TIMES_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_ACTIVITY_TIME } from '../../constants/Keys'
import useIsOnline from '../../dataProvider/hooks/useIsOnline'
import useFilter from '../../hooks/useFilter'
import useUserSettings from '../../hooks/useUserSettings'
import { LoadingType } from '../../types'
import { ticketListSplitViewEnabledAtom } from '../../utils/stateManager'
import { utils } from '../../utils/utils'

export type TicketListType = 'normal' | 'carousel'
export type TicketListView = 'normal' | 'map'

type Props = {
  tickets: (string | Ticket)[]
  loading: LoadingType
  buttonAnimationValue: Animated.Value
  selectedTicketId?: string
  type: TicketListType
  view: TicketListView
  reloadTickets: () => void
  onCreate?: () => void
  onTicketPress: (ticket: Ticket) => void
}

const TicketList = memo(function TicketList({
  tickets,
  loading,
  buttonAnimationValue,
  selectedTicketId,
  type,
  view,
  reloadTickets,
  onCreate,
  onTicketPress,
}: Props) {
  const { search } = useSearch()
  const { i18n } = useLanguage()
  const isOnline = useIsOnline()
  const { sessionKey } = useAuthentication()
  const { userSettings } = useUserSettings()
  const { filters, groups, orders } = useFilter()
  const svEnabled = useRecoilValue(ticketListSplitViewEnabledAtom(sessionKey))
  const splitViewEnabled = useMemo(() => (view === 'map' ? false : svEnabled), [view, svEnabled])

  const [uuid, setUuid] = useState(utils.generateUuid())

  const { item: times, loadItem: getTimes, loading: loadingTimes } = useControlledLoader(api.getActivityTime, { id: REQUEST_ACTIVITY_TIME })

  const listRef = createRef<FlashList<string | Ticket>>()

  useEvent({ key: REFRESH_TICKET_LIST_TIMES_EVENT_KEY }, () => refreshTimes())

  useEffect(() => {
    refreshTimes()
  }, [userSettings])

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })
  }, [filters, groups, orders, splitViewEnabled])

  useEffect(() => {
    setUuid(utils.generateUuid())
  }, [loadingTimes, selectedTicketId, splitViewEnabled])

  const refreshTimes = () => userSettings && userSettings.employeeId && getTimes({ employeeId: userSettings.employeeId, openTime: true })

  const renderItem = (elem: ListRenderItemInfo<Ticket | string>) => {
    const isLast = elem.index === tickets.length - 1
    const item = elem.item

    if (typeof item === 'string') return <></>

    return (
      <TicketListCard
        ticket={item}
        onPress={() => onTicketPress(item)}
        forceLayout={splitViewEnabled || view === 'map' ? 'small' : undefined}
        isSelected={item.id === selectedTicketId}
        type={type}
        view={view}
        timeRunning={times?.at(0)?.ticketId === item.id}
        style={[
          // eslint-disable-next-line react-native/no-inline-styles
          {
            marginTop: IMLayout.horizontalMargin,
            marginBottom: isLast ? IMLayout.horizontalMargin : 0,
            marginHorizontal: 2 * IMLayout.horizontalMargin,
          },
        ]}
      />
    )
  }

  const shotCreateTicketButton = isOnline && onCreate

  return (
    <IM.View style={IMLayout.flex.f1}>
      <FlashListData
        ref={listRef}
        data={tickets}
        loading={loading}
        noDataMessage={i18n.t('NO_TICKET_FOUND')}
        renderItem={renderItem}
        isSearching={search !== ''}
        refresh={reloadTickets}
        extraData={uuid}
      />
      {loading === false && (
        <>
          {shotCreateTicketButton && (
            <AnimatedButton id={ADD_TICKET_BUTTON_ID} value={buttonAnimationValue} icon={['fal', 'plus']} onPress={onCreate} />
          )}
          <TravelTimeButton
            id={ADD_TICKET_BUTTON_ID}
            value={buttonAnimationValue}
            bottom={shotCreateTicketButton ? CONSTANTS.secondButtonBottom : undefined}
          />
        </>
      )}
    </IM.View>
  )
})

export default TicketList
