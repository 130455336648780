import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle } from '@infominds/react-native-components'
import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

interface Props {
  title: string
  description?: string
  icon?: IconProp
  iconSize?: number
  iconColor?: string
  onPress?: () => void
}

export default function HeaderWithPressable({ title, icon, iconColor, iconSize, description, onPress }: Props) {
  return (
    <Pressable style={[IMLayout.flex.f1, onPress !== undefined && styles.marginLeft]} disabled={onPress === undefined} onPress={onPress}>
      <IM.View style={[IMLayout.flex.f1, styles.titleContainer, onPress === undefined && styles.padding]}>
        <IM.View>
          <IM.Text style={[styles.title, { color: IMStyle.palette.white }]} numberOfLines={1} selectable>
            {title}
          </IM.Text>
          {description && (
            <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular, color: IMStyle.palette.white }} numberOfLines={1}>
              {description}
            </IM.Text>
          )}
        </IM.View>
        {icon && <IM.Icon icon={icon} color={iconColor ?? IMStyle.palette.white} size={iconSize} style={styles.icon} />}
        {onPress && <IM.Icon icon={['fal', 'chevron-right']} color={IMStyle.palette.white} style={styles.icon} />}
      </IM.View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: IMStyle.typography.fontSizeMedium,
    color: IMStyle.palette.white,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 32,
    paddingHorizontal: IMLayout.horizontalMargin,
  },
  icon: {
    paddingLeft: 8,
  },
  marginLeft: { marginLeft: -6 },
  padding: { paddingHorizontal: 0 },
})
