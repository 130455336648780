import { useEvent, useLanguage } from '@infominds/react-native-components'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import cloneDeep from 'lodash/cloneDeep'
import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import api from '../../apis/apiCalls'
import { ArticleSparePart } from '../../apis/types/apiResponseTypes'
import FlashListData from '../../components/FlashListData'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import NoEntry from '../../components/NoEntry'
import useSearch from '../../components/screen/hooks/useSearch'
import { TICKET_SPARE_PARTS_DONE_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { EDIT_ACTIVITY_SPARE_PARTS_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_TICKET_ARTICLE_SPARE_PARTS } from '../../constants/Keys'
import { ALL_DEPOTS_ID, SparePartsFilterType, SparePartsOrderType } from '../../contexts/SparePartsFilterContext'
import useIsOnline from '../../dataProvider/hooks/useIsOnline'
import useSparePartsFilter from '../../hooks/useSparePartsFilter'
import { sparePartsScreenFilterEnableAtom } from '../../utils/stateManager'
import { SparePartsDetailsViewParams } from './SparePartsDetailsView'
import SparePartsViewRenderItem from './SparePartsViewRenderItem'

export default function SparePartsView() {
  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { filters, orders } = useSparePartsFilter()
  const isOnline = useIsOnline()
  const navigation = useMasterDetail<SparePartsDetailsViewParams>()

  const listRef = createRef<FlashList<ArticleSparePart>>()

  const [fetchEnabled, setFetchEnabled] = useState(false)
  const [selected, setSelected] = useState('')

  const enabledFilters = useRecoilValue(sparePartsScreenFilterEnableAtom)

  const {
    item: spareParts,
    loadItem: getSpareParts,
    allDataLoaded,
    loadMore,
    loading,
  } = useInfiniteLoader(api.getArticleSpareParts, { id: REQUEST_TICKET_ARTICLE_SPARE_PARTS, chuckSize: CONSTANTS.defaultChuckSize })
  useEvent({ key: EDIT_ACTIVITY_SPARE_PARTS_EVENT_KEY }, () => refresh())

  useEffect(() => {
    if (filters.length !== 0 || !isOnline) refresh()
  }, [filters, search])

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })
  }, [filters, orders, search])

  const refresh = useCallback(() => {
    navigation.setDetailParams(undefined)
    setSelected('')

    let id: string | undefined

    filters.forEach(filter => {
      if (filter.id === SparePartsFilterType.Depots) {
        const found = filter.elements.find(depot => depot.active)

        if (found) {
          id = found.id
        }
      }
    })

    const depositId = id === ALL_DEPOTS_ID ? undefined : id
    const inStock = id !== undefined

    setFetchEnabled(depositId !== undefined || search !== '' || inStock !== false)
    getSpareParts({
      direction: 'Installation',
      depositId: depositId,
      searchText: search,
      inStock: inStock,
    })
  }, [filters, navigation, search])

  const filteredSpareParts = useMemo(() => {
    if (!(loading !== 'reloading' && loading !== 'aborted')) return []

    let sparePartsClone = cloneDeep(spareParts ?? [])

    const activeOrder = orders.find(order => order.active)
    switch (activeOrder?.data.id) {
      case SparePartsOrderType.ArticleCode: {
        sparePartsClone.sort((a, b) => a.articleCode.localeCompare(b.articleCode))
        break
      }
      case SparePartsOrderType.ArticleName: {
        sparePartsClone.sort((a, b) => a.articleSearchtext.localeCompare(b.articleSearchtext))
        break
      }
      case SparePartsOrderType.SerialNumberAscending: {
        const withSn = sparePartsClone.filter(el => el.serialnumber !== undefined)
        const withOutSn = sparePartsClone.filter(el => el.serialnumber === undefined)

        withSn.sort((a, b) => (a.serialnumber && b.serialnumber ? a.serialnumber.localeCompare(b.serialnumber) : 0))
        sparePartsClone = [...withSn, ...withOutSn]
        break
      }
      case SparePartsOrderType.SerialNumberDescending: {
        const withSn = sparePartsClone.filter(el => el.serialnumber !== undefined)
        const withOutSn = sparePartsClone.filter(el => el.serialnumber === undefined)

        withSn.sort((a, b) => (a.serialnumber && b.serialnumber ? b.serialnumber.localeCompare(a.serialnumber) : 0))
        sparePartsClone = [...withSn, ...withOutSn]
        break
      }
    }

    return sparePartsClone
  }, [orders, spareParts, loading])

  const renderItem = useCallback(
    (info: ListRenderItemInfo<ArticleSparePart | string>) => {
      return <SparePartsViewRenderItem info={info} selected={selected} setSelected={setSelected} disabled={!isOnline} />
    },
    [selected, isOnline]
  )

  return (
    <>
      {fetchEnabled ? (
        <FlashListData
          ref={listRef}
          data={filteredSpareParts}
          loading={loading}
          renderItem={renderItem}
          noDataMessage={i18n.t('NO_SPARE_PARTS_FOUND')}
          refresh={refresh}
          id={TICKET_SPARE_PARTS_DONE_ID}
          keyExtractor={item => item.articleId + (item.serialnumberId ?? '') + item.depositId}
          extraData={selected}
          onLoadMore={loadMore}
          allDataLoaded={allDataLoaded}
        />
      ) : (
        <>{enabledFilters && <NoEntry error errorMessage={i18n.t('SPARE_PART_FILTER_NEEDED')} description="" />}</>
      )}
    </>
  )
}
