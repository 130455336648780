import { IM, SpacingProps, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo, useImperativeHandle, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Activity, TicketActivity } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import useRequest from '../../components/Infominds/hooks/useRequest'
import { DescriptionInput } from '../../components/input/DescriptionInput'
import { REFRESH_ACTIVITY_VIEW_EVENT_KEY } from '../../constants/EmitterKeys'
import useUserSettings from '../../hooks/useUserSettings'
import EditDescriptionModal, { EditDescriptionModalType } from '../../modals/EditDescriptionModal'
import appUtils from '../../utils/appUtils'

export type ActivityDescriptionCardRef = {
  reset: () => void
}

enum ModalType {
  TECHNICAL = 'Technical',
  CUSTOMER = 'Customer',
  NEW_ACTIVITY = 'NewActivity',
}

type Props = {
  activity: TicketActivity
  spacing?: SpacingProps
  disabled?: boolean
  disableNextActivity?: boolean
}

const ActivityDescriptionCard = memo(
  forwardRef(function ActivityDescriptionCard(
    { activity, spacing, disabled = false, disableNextActivity = false }: Props,
    ref: ForwardedRef<ActivityDescriptionCardRef>
  ) {
    useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          setTech(activity.technicalDescription)
          setCustomerNote(activity.customerDescription)
          setNewActivity(activity.nextActivityDescription)
        },
      }),
      []
    )

    const { i18n } = useLanguage()
    const { userSettings } = useUserSettings()
    const controller = useModalController<EditDescriptionModalType>()

    const [tech, setTech] = useState(activity.technicalDescription)
    const [customerNote, setCustomerNote] = useState(activity.customerDescription)
    const [newActivity, setNewActivity] = useState(activity.nextActivityDescription)

    const { request, loading, error, reset } = useRequest(api.editActivity, { onResult: () => emit(), disableAlert: true })
    const { emit } = useEvent({ key: REFRESH_ACTIVITY_VIEW_EVENT_KEY })

    const code = useMemo(() => appUtils.customerLanguageToCountryCode(activity.customerLanguage), [])

    return (
      <>
        <IM.Card spacing={spacing} head={<CardLeftTitle text={i18n.t('DESCRIPTION')} />} noContentSpacing>
          <IM.View style={styles.card}>
            <DescriptionInput
              title={i18n.t('TECHNICAL_DESCRIPTION')}
              value={tech ?? ''}
              editable={!disabled}
              onPress={() => {
                controller.show({ title: i18n.t('EDIT_TECHNICAL_DESCRIPTION'), value: tech, id: ModalType.TECHNICAL })
              }}
            />
            <DescriptionInput
              title={i18n.t('CUSTOMER_DESCRIPTION')}
              value={customerNote ?? ''}
              editable={!disabled && (userSettings ? userSettings.allowEditingCustomerDescriptionOfActivity : false)}
              flagCode={code}
              onPress={() => {
                controller.show({ title: i18n.t('EDIT_CUSTOMER_DESCRIPTION'), value: customerNote, id: ModalType.CUSTOMER, flag: code })
              }}
            />
            {activity.showNextActivityDescription && (
              <DescriptionInput
                title={i18n.t('NEW_ACTIVITY_DESCRIPTION')}
                value={newActivity ?? ''}
                editable={!disableNextActivity && !disabled}
                onPress={() => {
                  controller.show({ title: i18n.t('EDIT_NEW_ACTIVITY_DESCRIPTION'), value: newActivity, id: ModalType.NEW_ACTIVITY })
                }}
              />
            )}
          </IM.View>
        </IM.Card>
        <EditDescriptionModal
          controller={controller}
          onClose={reset}
          loading={loading !== false}
          error={error}
          onSave={(value, modalId) => {
            switch (modalId) {
              case ModalType.TECHNICAL: {
                request({
                  id: activity.id,
                  technicalDescription: value ?? '',
                } as Activity)
                break
              }
              case ModalType.CUSTOMER: {
                request({
                  id: activity.id,
                  customerDescription: value ?? '',
                } as Activity)
                break
              }
              case ModalType.NEW_ACTIVITY: {
                request({
                  id: activity.id,
                  nextActivityDescription: value ?? '',
                } as Activity)
                break
              }
            }
          }}
        />
      </>
    )
  })
)

export default ActivityDescriptionCard

const styles = StyleSheet.create({
  card: {
    paddingTop: 6,
    paddingBottom: 12,
    paddingHorizontal: 12,
  },
})
