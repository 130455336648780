import { IM, SpacingProps, useDimensions, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { createRef, memo, useEffect, useRef, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { Message } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import Chip from '../../components/Chip'
import Error from '../../components/Error'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import MessageInput from '../../components/input/MessageInput'
import MessageComponent from '../../components/MessageComponent'
import SkeletonText from '../../components/skeleton/SkeletonText'
import { EDIT_NOTE_DATA_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_MESSAGES } from '../../constants/Keys'
import { SMNoteType, ThemeColorExpanded } from '../../types'
import TimeUtils from '../../utils/TimeUtils'

interface Props {
  type: SMNoteType
  id: string
  spacing?: SpacingProps
  onlyRead?: boolean
}

const MessagesCard = memo(function MessagesCard({ type, id, spacing, onlyRead }: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const { item: messages, loadItem, loading, setItem: setMessages } = useControlledLoader(api.getMessages, { id: REQUEST_MESSAGES })
  const { request: sendMessage, loading: loadingSend, response: newCratedMessage } = useRequest(api.sendMessage)
  const { request: deleteMessage, loading: loadingDeleting } = useRequest(api.deleteMessage)
  const { request: updateLastRead } = useRequest(api.updateMessageLastRead)

  const scrollView = createRef<ScrollView>()
  const newMessageIndex = useRef<number | undefined>()

  const [deleting, setDeleting] = useState(false)
  const [newMessage, setNewMessage] = useState('')
  const [selected, setSelected] = useState<Message | undefined>()

  useEvent({ key: EDIT_NOTE_DATA_EVENT_KEY + type }, () => refresh())

  useEffect(() => {
    refresh()
  }, [id])

  useEffect(() => {
    if (loadingSend === false) {
      setNewMessage('')
      newCratedMessage ? setMessages(prev => prev?.concat(newCratedMessage)) : refresh()
    }
  }, [loadingSend, newCratedMessage])

  useEffect(() => {
    if (loadingDeleting === false) {
      setSelected(undefined)
      setDeleting(false)
      refresh()
    }

    if (loadingDeleting === 'catched') {
      setDeleting(false)
    }
  }, [loadingDeleting])

  useEffect(() => {
    if (messages) {
      let lastExternalNotReadMessage: Message | undefined

      messages.forEach(message => {
        if (message.external && message.readOn === undefined) {
          lastExternalNotReadMessage = message
        }
      })

      lastExternalNotReadMessage && updateLastRead({ fkId: lastExternalNotReadMessage.fkId, type })
    }
  }, [messages])

  const refresh = () => loadItem({ type, fkId: id })

  const color = colorScheme === 'light' ? theme.chip.background : theme.header.detail.skeletonBackground

  return (
    <IM.Card noContentSpacing head={<CardLeftTitle text={i18n.t('MESSAGES')} />} spacing={spacing}>
      {loading === false ? (
        <IM.View style={styles.card}>
          {messages?.length === 0 ? (
            <IM.View style={styles.container}>
              <IM.Text secondary>{i18n.t('NO_MESSAGES')}</IM.Text>
            </IM.View>
          ) : (
            <View style={styles.scrollContainer}>
              <ScrollView
                ref={scrollView}
                nestedScrollEnabled
                keyboardShouldPersistTaps="always"
                contentOffset={{ y: 10000, x: 0 }}
                // eslint-disable-next-line react-native/no-inline-styles
                contentContainerStyle={{ paddingHorizontal: isSmallDevice ? 0 : 10 }}
                onContentSizeChange={() => scrollView.current?.scrollToEnd()}>
                {messages?.map((message, index) => {
                  if (message.external && newMessageIndex.current === undefined && message.readOn === undefined) {
                    newMessageIndex.current = index
                  }

                  const internal = !message.external

                  const opacity = 0.6
                  const previousDate = index === 0 ? '' : TimeUtils.format(messages[index - 1].date, language)
                  const currentDate = TimeUtils.format(message.date, language)
                  return (
                    <IM.View spacing="top" key={message.id}>
                      {newMessageIndex.current === index && (
                        <IM.View style={[styles.newMessagesContainer, { backgroundColor: color, opacity }]}>
                          <IM.Text>{i18n.t('NEW_MESSAGES')}</IM.Text>
                          <IM.Icon icon={['fal', 'chevron-down']} color={theme.text.default} size={10} style={styles.newMessageIcon} />
                        </IM.View>
                      )}
                      {previousDate !== currentDate && (
                        <IM.View style={styles.dateContainer}>
                          <Chip
                            value={TimeUtils.format(message.date, language)}
                            style={[styles.dateChip, { backgroundColor: color, opacity }]}
                            size="small"
                          />
                        </IM.View>
                      )}
                      <Pressable
                        onPress={() => setSelected(undefined)}
                        onLongPress={() => (!onlyRead && !message.external ? setSelected(message) : setSelected(undefined))}>
                        <MessageComponent
                          message={message}
                          position={internal ? 'right' : 'left'}
                          backgroundColor={internal ? theme.pressable.animatedHighlight : color}
                          selected={selected && selected.id === message.id}
                          deleteDisabled={deleting}
                          onDeleted={deleteId => {
                            setDeleting(true)
                            deleteMessage({ id: deleteId })
                          }}
                        />
                      </Pressable>
                    </IM.View>
                  )
                })}
              </ScrollView>
            </View>
          )}
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <IM.View style={{ margin: 10, marginTop: 6 }}>
            {!onlyRead && (
              <MessageInput
                value={newMessage}
                onChangeText={setNewMessage}
                loading={loadingSend !== false && loadingSend !== 'catched'}
                multiline
                fixMultilineHeight
                numberOfLines={3}
                onSend={() => {
                  sendMessage({ fkId: id, type, note: newMessage })
                }}
              />
            )}
          </IM.View>
        </IM.View>
      ) : (
        <IM.View style={styles.container}>
          {loading === 'catched' ? (
            <Error />
          ) : (
            <>
              <SkeletonText width="50%" />
              <SkeletonText width="80%" spacing="top" />
              <SkeletonText width="100%" spacing="top" />
            </>
          )}
        </IM.View>
      )}
    </IM.Card>
  )
})

export default MessagesCard

const styles = StyleSheet.create({
  container: {
    margin: 12,
  },
  dateContainer: { flex: 1, alignItems: 'center', justifyContent: 'center', marginBottom: 3, alignSelf: 'center' },
  dateChip: { paddingHorizontal: 4 },
  newMessageIcon: { paddingHorizontal: 8 },
  newMessagesContainer: { justifyContent: 'center', alignItems: 'center', marginBottom: 8, flexDirection: 'row' },
  scrollContainer: { maxHeight: 350 },
  card: { width: '100%', alignSelf: 'center' },
})
