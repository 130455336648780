/* eslint-disable react-native/no-inline-styles */

import Color from 'color'
import dayjs from 'dayjs'
import React from 'react'
import { EventItem } from 'react-big-scheduler-stch'
import { StyleSheet } from 'react-native'

import TicketIcon from '../../cards/ticketList/components/TicketIcon'
import { base64Utils } from '../../utils/base64Utils'
import { EventTitle } from './types'

type Props = {
  event: EventItem
  isStart: boolean
  isEnd: boolean
  mustAddCssClass: string
  mustBeHeight: number
}

export default function PlannerEvent({ event, isStart, isEnd, mustAddCssClass, mustBeHeight }: Props) {
  // const navigation = useNavigation<NavigationProp<PlanningStackParamList>>()

  const borderWidthStart = isStart ? '4px' : '0'
  const borderWidthEnd = isEnd ? '4px' : '0'
  const borderRadiusStart = isStart ? '4px' : '0'
  const borderRadiusEnd = isEnd ? '4px' : '0'
  const sameDay = dayjs(event.start).isSame(dayjs(event.end), 'day')

  const object = base64Utils.decode<EventTitle>(event.title)

  const color = {
    background: event.bgColor as string,
    accent: Color(event.bgColor as string)
      .darken(0.4)
      .toString(),
  }

  return (
    <div
      key={event.id}
      className={mustAddCssClass}
      style={{
        backgroundColor: color.background,
        height: mustBeHeight,
        borderTopLeftRadius: borderRadiusStart,
        borderBottomLeftRadius: borderRadiusStart,
        borderTopRightRadius: borderRadiusEnd,
        borderBottomRightRadius: borderRadiusEnd,
      }}
      onClick={() => {
        // TODO: restore
        // navigation.navigate('PlanningActivityCommonStack', {
        //   screen: 'ActivityPlanning',
        //   params: {
        //     // @ts-ignore: ok
        //     activityId: event.activityId,
        //     // @ts-ignore: ok
        //     ticketId: event.ticketId,
        //     // @ts-ignore: ok
        //     documentId: event.ticketDocumentId,
        //     // @ts-ignore: ok
        //     activityCode: event.activityCode,
        //     // @ts-ignore: ok
        //     ticketCode: event.ticketCode,
        //     start: false,
        //   },
        // })
      }}>
      <div
        style={{
          backgroundColor: color.background,
          margin: 0,
          marginLeft: 0,
          marginRight: 0,
          height: '100%',
          width: '100%',
          display: 'flex',
        }}>
        <div
          style={{
            ...styles.leftGrip,
            ...{
              borderLeft: `${borderWidthStart} solid ${event.startResizable ? color.accent : color.background}`,
            },
          }}
        />
        <div style={{ display: 'flex', width: '100%' }}>
          <TicketIcon activityType={object.activityType} iconSize={20} iconColor={color.accent} style={{ alignSelf: 'center', paddingLeft: 2 }} />
          <div style={{ display: 'grid', paddingLeft: 5, paddingTop: 3 }}>
            <span style={styles.text}>{object.activityCode}</span>
            <span style={{ ...styles.text, ...{ fontSize: '11px', paddingBottom: 3, fontWeight: 'light' } }}>{object.ticketCode}</span>
            <span style={styles.text}>
              {dayjs(event.start).format(sameDay ? 'LT' : 'D MMM YYYY LT')} - {dayjs(event.end).format(sameDay ? 'LT' : 'D MMM YYYY LT')}
            </span>
          </div>
        </div>
        <div
          style={{
            ...styles.rightGrip,
            ...{
              borderRight: `${borderWidthEnd} solid ${event.endResizable ? color.accent : color.background}`,
            },
          }}
        />
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  leftGrip: { margin: 3, flex: 1 },
  rightGrip: { margin: 3, flex: 1 },
  //@ts-ignore: works on web
  text: { overflow: 'hidden', textOverflow: 'ellipsis', textAlignVertical: 'middle', whiteSpace: 'nowrap', fontSize: '12px' },
})
