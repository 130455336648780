import { IM, IMLayout, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { createRef, memo, useState } from 'react'
import { StyleSheet, TextStyle } from 'react-native'

import usePendingSignature from '../../hooks/usePendingSignature'
import { SignaturePadRef, ThemeColorExpanded } from '../../types'
import PressableTextIcon from '../Infominds/PressableTextIcon'
import SignaturePad from '../SignaturePad'
import BaseTextInputTitle from './baseTextInput/BaseTextInputTitle'

interface Props {
  width: number | undefined
  height: number | undefined
  title?: string
  titleFontWeight?: TextStyle['fontWeight']
  spacing?: SpacingProps
  disabled?: boolean
  onOk: (signature: string, penColor: string) => void
  onCancel?: () => void
}

const SignatureInput = memo(function SignatureInput({ height, width, title, titleFontWeight, spacing, disabled, ...others }: Props) {
  const { onOk, onCancel } = others
  const { i18n } = useLanguage()
  const { onSaveSignature, onClearSignature } = usePendingSignature()
  const { theme } = useTheme<ThemeColorExpanded>()
  const ref = createRef<SignaturePadRef>()

  const [signature, setSign] = useState<string | null>(null)

  const handleOk = (sign: string, penColor: string) => {
    setSign(sign)
    onOk(sign, penColor)
  }

  const handleCancel = () => {
    onClearSignature()
    onCancel?.()
    setSign(null)
    ref.current?.clearSignature()
  }

  const handleSave = () => {
    onSaveSignature()
    ref.current?.readSignature()
  }

  const disabledSave = disabled || signature !== null

  return (
    <IM.View spacing={spacing}>
      <IM.View style={[IMLayout.flex.row, styles.container]}>
        {title && <BaseTextInputTitle title={title} fontWeight={titleFontWeight} />}
        <IM.View style={IMLayout.flex.row}>
          <PressableTextIcon
            icon={['fal', 'floppy-disk']}
            onPress={handleSave}
            pressableStyle={styles.pressable}
            hitSlop={{ bottom: 20, left: 15, top: 20, right: 0 }}
            color={disabledSave ? theme.button.disabledBackground : theme.general.info}
            disabled={disabledSave}>
            {i18n.t('SAVE')}
          </PressableTextIcon>
          <PressableTextIcon
            icon={['fal', 'times']}
            onPress={handleCancel}
            pressableStyle={styles.pressable}
            hitSlop={{ bottom: 20, right: 12, top: 20, left: 0 }}
            secondary={disabled}
            disabled={disabled}>
            {i18n.t('CLEAR')}
          </PressableTextIcon>
        </IM.View>
      </IM.View>
      <SignaturePad ref={ref} signature={signature} height={height} width={width} onOk={handleOk} disabled={disabled} />
    </IM.View>
  )
})

export default SignatureInput

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  pressable: {
    padding: 2,
    paddingHorizontal: 6,
  },
})
