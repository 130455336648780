import { Storage, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import { STORAGE_KEY_FILTER_CONTEXT_FILTER, STORAGE_KEY_FILTER_CONTEXT_GROUP, STORAGE_KEY_FILTER_CONTEXT_ORDER } from '../constants/Keys'
import { Filter, FilterElement, FilterStorageType, Group, Order, StorageType } from '../types'
import ticketUtils from '../utils/TicketUtils'

export enum GroupType {
  Zone = 'GroupZoneKey',
  Customer = 'GroupCustomerKey',
  Assistance = 'GroupAssistanceKey',
}

export enum OrderType {
  DateDescending = 'OrderDateDescendingKey',
  DateAscending = 'OrderDateAscendingKey',
  PlanningDescending = 'OrderPlanningDescendingKey',
  PlanningAscending = 'OrderPlanningAscendingKey',
  TownDescending = 'TownDescendingKey',
  TownAscending = 'TownAscendingKey',
  Priority = 'OrderPriorityKey',
}

export enum FilterType {
  Teams = 'FilterTeamsKey',
  Activity = 'FilterActivityKey',
  Assistance = 'FilterAssistanceKey',
  Zone = 'FilterZoneKey',
  PlanDate = 'PlanDateKey',
}

export enum PlanDateFilterType {
  PlanDateFrom = 'PlanDateFromKey',
  PlanDateTo = 'PlanDateToKey',
}

interface ContextProps {
  filters: Filter<FilterType>[]
  groups: Group<GroupType>[]
  orders: Order<OrderType>[]
  activeCount: number
  setFilters: (filters: Filter<FilterType>[]) => void
  setGroups: (groups: Group<GroupType>[]) => void
  setOrders: (orders: Order<OrderType>[]) => void
  initFilters: (activity: FilterElement[], assistance: FilterElement[], zone: FilterElement[], teams: FilterElement[]) => void
  changeFilterStatus: (id: GroupType | OrderType | FilterType, filterElemId?: string) => void
}

const FilterContext = createContext<ContextProps | undefined>(undefined)

interface ProviderProps extends PropsWithChildren {
  storageKeyUniqueId: string
  disableOrderByPlan?: boolean
  disableOrderByPriority?: boolean
  disableOrderByTown?: boolean
  disableFilterByAssistance?: boolean
  disableFilterByPlanDate?: boolean
  disableGroupByMaintenance?: boolean
}

export const FilterProvider = ({
  storageKeyUniqueId,
  disableOrderByPlan = false,
  disableOrderByPriority = false,
  disableOrderByTown = false,
  disableFilterByAssistance = false,
  disableGroupByMaintenance = false,
  disableFilterByPlanDate = false,
  children,
}: ProviderProps) => {
  const { i18n, language } = useLanguage()

  const dateOrder: Order<OrderType>[] = [
    {
      active: false,
      data: { id: OrderType.DateDescending, name: ticketUtils.getNameByType(OrderType.DateDescending, i18n), icon: ['fal', 'arrow-up'] },
    },
    {
      active: false,
      data: { id: OrderType.DateAscending, name: ticketUtils.getNameByType(OrderType.DateAscending, i18n), icon: ['fal', 'arrow-down'] },
    },
  ]
  const planningOrder: Order<OrderType>[] = [
    {
      active: false,
      data: {
        id: OrderType.PlanningDescending,
        name: ticketUtils.getNameByType(OrderType.PlanningDescending, i18n),
        icon: ['fal', 'arrow-up'],
      },
    },
    {
      active: false,
      data: {
        id: OrderType.PlanningAscending,
        name: ticketUtils.getNameByType(OrderType.PlanningAscending, i18n),
        icon: ['fal', 'arrow-down'],
      },
    },
  ]
  const townOrder: Order<OrderType>[] = [
    {
      active: false,
      data: {
        id: OrderType.TownAscending,
        name: ticketUtils.getNameByType(OrderType.TownAscending, i18n),
        icon: ['fal', 'arrow-down-a-z'],
      },
    },
    {
      active: false,
      data: {
        id: OrderType.TownDescending,
        name: ticketUtils.getNameByType(OrderType.TownDescending, i18n),
        icon: ['fal', 'arrow-up-a-z'],
      },
    },
  ]

  const [groups, setGroups] = useState<Group<GroupType>[]>(
    [
      ...[
        { active: false, data: { id: GroupType.Customer, name: ticketUtils.getNameByType(GroupType.Customer, i18n) } },
        { active: false, data: { id: GroupType.Zone, name: ticketUtils.getNameByType(GroupType.Zone, i18n) } },
      ],
      ...(disableGroupByMaintenance
        ? []
        : [{ active: false, data: { id: GroupType.Assistance, name: ticketUtils.getNameByType(GroupType.Assistance, i18n) } }]),
    ].sort((a, b) => a.data.name.localeCompare(b.data.name))
  )
  const [orders, setOrders] = useState<Order<OrderType>[]>(
    [
      ...dateOrder,
      ...(disableOrderByPlan ? [] : planningOrder),
      ...(disableOrderByTown ? [] : townOrder),
      ...(disableOrderByPriority
        ? []
        : [{ active: false, data: { id: OrderType.Priority, name: ticketUtils.getNameByType(OrderType.Priority, i18n) } }]),
    ].sort((a, b) => a.data.name.localeCompare(b.data.name))
  )
  const [filters, setFilters] = useState<Filter<FilterType>[]>([])

  const groupsStorage = Storage<StorageType[]>(STORAGE_KEY_FILTER_CONTEXT_GROUP + storageKeyUniqueId)
  const ordersStorage = Storage<StorageType[]>(STORAGE_KEY_FILTER_CONTEXT_ORDER + storageKeyUniqueId)
  const filtersStorage = Storage<FilterStorageType<FilterType>[]>(STORAGE_KEY_FILTER_CONTEXT_FILTER + storageKeyUniqueId)

  useEffect(() => {
    groupsStorage
      .load()
      .then(loaded => {
        if (loaded !== null) {
          const clone = cloneDeep(groups).map(elem => {
            const found = loaded.find(ld => ld.id === elem.data.id)

            if (found && typeof found.value === 'boolean') {
              return { ...elem, active: found.value }
            } else {
              return elem
            }
          })

          setGroups(clone)
        }
      })
      .catch(err => console.error('Failed loading ticket group by', err))

    ordersStorage
      .load()
      .then(loaded => {
        if (loaded !== null) {
          const clone = cloneDeep(orders).map(elem => {
            const found = loaded.find(ld => ld.id === elem.data.id)

            if (found && typeof found.value === 'boolean') {
              return { ...elem, active: found.value }
            } else {
              return elem
            }
          })

          setOrders(clone)
        }
      })
      .catch(err => console.error('Failed loading ticket sort by', err))
  }, [])

  useEffect(() => {
    setGroups(prv =>
      prv.map(el => {
        return { ...el, data: { ...el.data, name: ticketUtils.getNameByType(el.data.id, i18n) } }
      })
    )
    setOrders(prv =>
      prv.map(el => {
        return { ...el, data: { ...el.data, name: ticketUtils.getNameByType(el.data.id, i18n) } }
      })
    )
  }, [language])

  const initFilters = useCallback((activity: FilterElement[], assistance: FilterElement[], zone: FilterElement[], teams: FilterElement[]) => {
    filtersStorage
      .load()
      .then(loaded => {
        const activityToUpdate: FilterElement[] = []
        const assistanceToUpdate: FilterElement[] = []
        const teamsToUpdate: FilterElement[] = []
        const zoneToUpdate: FilterElement[] = []
        const planeDateToUpdate: FilterElement[] = []

        if (loaded !== null) {
          const loadedFilters: Filter<FilterType>[] = []

          loaded.forEach(el => {
            const elements: FilterElement[] = []

            if (el.id === FilterType.PlanDate) {
              el.data.forEach(data => {
                elements.push({
                  id: data.id,
                  value: typeof data.value === 'string' ? data.value : undefined,
                  active: typeof data.value === 'string' ? data.value !== undefined : false,
                })
              })
            } else {
              el.data.forEach(data => {
                elements.push({ id: data.id, active: typeof data.value === 'boolean' ? data.value : false })
              })
            }

            loadedFilters.push({ id: el.id, name: ticketUtils.getNameByType(el.id, i18n), elements })
          })

          loadedFilters.forEach(loadedFilter => {
            if (loadedFilter.id === FilterType.Activity) {
              activityToUpdate.push(...ticketUtils.mergeFilters(loadedFilter, activity))
            } else if (loadedFilter.id === FilterType.Assistance) {
              assistanceToUpdate.push(...ticketUtils.mergeFilters(loadedFilter, assistance))
            } else if (loadedFilter.id === FilterType.Teams) {
              teamsToUpdate.push(...ticketUtils.mergeFilters(loadedFilter, teams))
            } else if (loadedFilter.id === FilterType.Zone) {
              zoneToUpdate.push(...ticketUtils.mergeFilters(loadedFilter, zone))
            } else if (loadedFilter.id === FilterType.PlanDate) {
              loadedFilter.elements.forEach(el => {
                planeDateToUpdate.push({ id: el.id, value: el.value, active: el.active })
              })
            }
          })
        }

        const from = planeDateToUpdate.find(el => el.id === PlanDateFilterType.PlanDateFrom)
        const to = planeDateToUpdate.find(el => el.id === PlanDateFilterType.PlanDateTo)

        setFilters([
          ...[
            {
              id: FilterType.Activity,
              name: ticketUtils.getNameByType(FilterType.Activity, i18n),
              elements:
                activityToUpdate.length === 0
                  ? ticketUtils.removeDuplicateFilters(activity).sort((a, b) => a.id.localeCompare(b.id))
                  : activityToUpdate,
            },
          ],
          ...(disableFilterByAssistance
            ? []
            : [
                {
                  id: FilterType.Assistance,
                  name: ticketUtils.getNameByType(FilterType.Assistance, i18n),
                  elements:
                    assistanceToUpdate.length === 0
                      ? ticketUtils.removeDuplicateFilters(assistance).sort((a, b) => a.id.localeCompare(b.id))
                      : assistanceToUpdate,
                },
              ]),
          ...[
            {
              id: FilterType.Teams,
              name: ticketUtils.getNameByType(FilterType.Teams, i18n),
              elements:
                teamsToUpdate.length === 0 ? ticketUtils.removeDuplicateFilters(teams).sort((a, b) => a.id.localeCompare(b.id)) : teamsToUpdate,
            },
            {
              id: FilterType.Zone,
              name: ticketUtils.getNameByType(FilterType.Zone, i18n),
              elements: zoneToUpdate.length === 0 ? ticketUtils.removeDuplicateFilters(zone).sort((a, b) => a.id.localeCompare(b.id)) : zoneToUpdate,
            },
          ],
          ...(disableFilterByPlanDate
            ? []
            : [
                {
                  id: FilterType.PlanDate,
                  name: ticketUtils.getNameByType(FilterType.PlanDate, i18n),
                  elements: [
                    { id: PlanDateFilterType.PlanDateFrom, active: from ? from.active : false, value: from?.value ? from.value : '' },
                    { id: PlanDateFilterType.PlanDateTo, active: to ? to.active : false, value: to?.value ? to.value : '' },
                  ],
                },
              ]),
        ])
      })
      .catch(err => console.error('Failed loading ticket filter', err))
  }, [])

  const changeFilterStatus = useCallback(
    (id: GroupType | OrderType | FilterType, filterElemId?: string) => {
      if (Object.values(GroupType).includes(id as GroupType)) {
        const clone = cloneDeep(groups)

        clone.forEach(filter => {
          if (filter.data.id === id) {
            filter.active = !filter.active
          }
        })

        handleGroupsUpdate(clone)
      } else if (Object.values(OrderType).includes(id as OrderType)) {
        const clone = cloneDeep(orders)

        clone.forEach(filter => {
          if (filter.data.id === id) {
            filter.active = !filter.active
          }
        })

        handleOrderUpdate(clone)
      } else {
        const clone = cloneDeep(filters)

        clone.forEach(filter => {
          if (filter.id === id) {
            if (filter.id === FilterType.PlanDate) {
              filter.elements.forEach(el => {
                el.value = undefined
                el.active = false
              })
            } else {
              filter.elements.forEach(el => {
                if (el.id === filterElemId) {
                  el.active = !el.active
                }
              })
            }
          }
        })

        handleFilterUpdate(clone)
      }
    },
    [filters, orders, groups]
  )

  const handleGroupsUpdate = (newGroups: Group<GroupType>[]) => {
    const toSave: StorageType[] = []
    newGroups.forEach(order => toSave.push({ id: order.data.id, value: order.active }))
    groupsStorage.save(toSave).catch(err => console.error('Failed saving ticket group by', err))
    setGroups(newGroups)
  }

  const handleOrderUpdate = (newOrders: Order<OrderType>[]) => {
    const toSave: StorageType[] = []
    newOrders.forEach(order => toSave.push({ id: order.data.id, value: order.active }))
    ordersStorage.save(toSave).catch(err => console.error('Failed saving ticket sort by', err))
    setOrders(newOrders)
  }

  const handleFilterUpdate = (newFilters: Filter<FilterType>[]) => {
    const toSave: FilterStorageType<FilterType>[] = []
    newFilters.forEach(filter => {
      const data: StorageType[] = []

      if (filter.id === FilterType.PlanDate) {
        filter.elements.forEach(el => {
          el.value && data.push({ id: el.id, value: el.value })
        })
      } else {
        filter.elements.forEach(el => {
          data.push({ id: el.id, value: el.active })
        })
      }

      toSave.push({ id: filter.id, data })
    })

    filtersStorage.save(toSave).catch(err => console.error('Failed saving ticket filter', err))
    setFilters(newFilters)
  }

  const activeCount = useMemo(() => {
    let counter = 0
    filters.forEach(filter =>
      filter.elements.forEach(el => {
        if (el.active) counter += 1
      })
    )
    groups.filter(el => (el.active ? (counter += 1) : null))
    orders.filter(el => (el.active ? (counter += 1) : null))

    return counter
  }, [filters, groups, orders])

  const props = useMemo<ContextProps>(
    () => ({
      filters,
      groups,
      orders,
      activeCount,
      setFilters: handleFilterUpdate,
      setGroups: handleGroupsUpdate,
      setOrders: handleOrderUpdate,
      changeFilterStatus,
      initFilters,
    }),
    [filters, groups, orders, activeCount]
  )

  return <FilterContext.Provider value={props}>{children}</FilterContext.Provider>
}

export default FilterContext
