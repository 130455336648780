import { useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle } from 'react'

import { getApi } from '../apis/apiCalls'
import { REQUEST_ACTIVITY_SPARE_PART_PRICE } from '../constants/Keys'
import { useDataProvider } from '../dataProvider/hooks/useDataProvider'
import useIsOnline from '../dataProvider/hooks/useIsOnline'
import useUserSettings from '../hooks/useUserSettings'
import { SparePartCardRef } from '../types'
import appUtils from '../utils/appUtils'
import useControlledLoader from './Infominds/hooks/useControlledLoader'
import PriceInput, { PrinceInputProps } from './input/PriceInput'

interface Props extends PrinceInputProps {
  activityId: string
  articleId: string | undefined
  serialnumberId?: string
  onDefaultValueFetched: ((text: string) => void) | undefined
}

function SparePartsPrice(
  { activityId, articleId, serialnumberId, onDefaultValueFetched, ...princeInputProps }: Props,
  ref: ForwardedRef<SparePartCardRef>
) {
  useImperativeHandle(ref, () => ({
    reset: () => {
      return
    },
  }))

  const { language } = useLanguage()
  const { userSettings } = useUserSettings()
  const isOnline = useIsOnline()
  const { client } = useDataProvider()

  const {
    item: price,
    loadItem: getPrice,
    loading: loading,
  } = useControlledLoader(getApi(client).getActivitySparePartsPrice, {
    id: REQUEST_ACTIVITY_SPARE_PART_PRICE + activityId + (articleId ?? 'dummyArticle') + (serialnumberId ?? '-'),
  })

  useEffect(() => {
    refresh()
  }, [activityId, articleId, serialnumberId])

  useEffect(() => {
    loading === false && price !== undefined && onDefaultValueFetched?.(appUtils.formatPrice(price, language))
  }, [price, loading, language])

  useEffect(() => {
    if (loading === 'catched' || loading === 'aborted') {
      princeInputProps.onChangeText?.('')
    }
  }, [loading])

  const refresh = () => {
    if (articleId && isOnline) {
      getPrice({ activityId, articleId, serialnumberId })
    }
  }

  return (
    <PriceInput
      {...princeInputProps}
      loading={loading === 'reloading' ? true : false}
      editable={
        userSettings?.allowChangePriceOfSpareParts && (loading === false || (loading === 'init' && princeInputProps.value !== undefined))
          ? princeInputProps.editable
          : false
      }
    />
  )
}

export default forwardRef(SparePartsPrice)
