import { IM, IMLayout, SpacingProps, useDimensions, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { createRef, memo, useEffect, useRef } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import DynamicView from '../../components/Infominds/DynamicView'
import PressableIcon from '../../components/Infominds/PressableIcon'
import PressableTextIcon from '../../components/Infominds/PressableTextIcon'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import QualityInput from '../../components/input/QualityInput'
import QualityCheck, { QualityCheckState } from '../../components/QualityCheck'
import QualityCheckCardNote from '../../components/QualityCheckCardNote'
import QualityTarget from '../../components/QualityTarget'
import useLayout from '../../hooks/useLayout'
import useQualityCheck from '../../hooks/useQualityCheck'
import EditNoteModal, { EditNoteModalType } from '../../modals/EditNoteModal'
import { CharacteristicsModified, NumberInputRef } from '../../types'
import qualityUtils from '../../utils/QualityUtils'

interface Props {
  characteristic: CharacteristicsModified
  hideSerialNumber: boolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

function QualityCheckCard({ characteristic, spacing, hideSerialNumber, style }: Props) {
  const { i18n } = useLanguage()
  const { isPortrait } = useLayout()
  const { isSmallDevice } = useDimensions()
  const { getQualityValue, setQualityNote } = useQualityCheck()
  const controller = useModalController<EditNoteModalType>()
  const note = getQualityValue(characteristic.qualityCharacteristicId, characteristic.serialnumberId, 'note')
  const lastElem = useRef(characteristic)
  const ref = createRef<NumberInputRef>()

  useEffect(() => {
    if (characteristic.serialnumberId !== lastElem.current.serialnumberId && characteristic.id !== lastElem.current.id) {
      lastElem.current = characteristic
      ref.current?.reset()
    }
  }, [characteristic])

  const handleState = (value: string | undefined): QualityCheckState => {
    if (
      characteristic.qualityCharacteristicCheck === undefined ||
      characteristic.qualityCharacteristicCheck.targetCompare === false ||
      ((characteristic.qualityCharacteristicFieldTyp === 'Text' || characteristic.qualityCharacteristicFieldTyp === 'Selection') &&
        characteristic.qualityCharacteristicCheck.targetText === undefined)
    ) {
      return undefined
    }

    return qualityUtils.qualityTargetComparisonCheck(characteristic.qualityCharacteristicFieldTyp, value, characteristic.qualityCharacteristicCheck)
  }

  const handleNotePress = () => controller.show({ value: note, title: i18n.t('EDIT_NOTE') })

  const forceLarge = !isSmallDevice && !isPortrait

  return (
    <>
      <IM.Card spacing={spacing} noContentSpacing style={style}>
        <IM.View style={styles.container}>
          <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
            <QualityCheck
              state={handleState(getQualityValue(characteristic.qualityCharacteristicId, characteristic.serialnumberId, 'value'))}
              spacing="right"
            />
            <IM.View style={[IMLayout.flex.row, IMLayout.flex.f1, styles.header]}>
              <IM.View style={[IMLayout.flex.row, forceLarge ? IMLayout.flex.f2 : IMLayout.flex.f1]}>
                <IM.Text numberOfLines={1}>
                  {characteristic.qualityCharacteristicDescription + (characteristic.qualityCharacteristicCheck?.isMandatory ? ' *' : '')}
                </IM.Text>
                {(note === undefined || note === '') && (
                  <IM.View style={styles.addNoteIconContainer}>
                    {isSmallDevice ? (
                      <PressableIcon
                        icon={['fal', 'plus']}
                        onPress={handleNotePress}
                        size={10}
                        // eslint-disable-next-line react-native/no-inline-styles
                        pressableStyle={{ padding: 3 }}
                        hitSlop={{ left: 8, right: 12, bottom: 4, top: 4 }}
                      />
                    ) : (
                      <PressableTextIcon
                        icon={['fal', 'plus']}
                        onPress={handleNotePress}
                        pressableStyle={styles.pressable}
                        hitSlop={{ bottom: 4, left: 4, top: 4, right: 4 }}
                        secondary>
                        {!isSmallDevice ? i18n.t('NOTE') : undefined}
                      </PressableTextIcon>
                    )}
                  </IM.View>
                )}
              </IM.View>
              <IM.View style={[IMLayout.flex.f1, IMLayout.alignItems.end]}>
                {characteristic.qualityCharacteristicCheck?.targetCompare && (
                  <QualityTarget check={characteristic.qualityCharacteristicCheck} type={characteristic.qualityCharacteristicFieldTyp} />
                )}
              </IM.View>
            </IM.View>
          </IM.View>
          {hideSerialNumber && (
            <IM.View style={styles.serialNumber}>
              <TextWithIcon icon={['fal', 'barcode']} secondary>
                {characteristic.serialnumber}
                {characteristic.serialnumberManufacturer ? ` (${characteristic.serialnumberManufacturer})` : ''}
              </TextWithIcon>
            </IM.View>
          )}
          <DynamicView forceLayout={forceLarge ? 'large' : 'small'}>
            <QualityCheckCardNote note={note} onPress={handleNotePress} />
            {/* eslint-disable-next-line react-native/no-inline-styles */}
            <IM.View style={[IMLayout.flex.f6, { marginTop: 6 }]}>
              <QualityInput
                ref={ref}
                type={characteristic.qualityCharacteristicFieldTyp}
                selectorTitle={characteristic.qualityCharacteristicDescription}
                qualityCharacteristicId={characteristic.qualityCharacteristicId}
                qualityCharacteristicDetails={characteristic.qualityCharacteristicDetails}
                qualityCharacteristicDecimal={characteristic.qualityCharacteristicDecimal}
                serialNumberId={characteristic.serialnumberId}
              />
            </IM.View>
          </DynamicView>
        </IM.View>
      </IM.Card>
      <EditNoteModal
        controller={controller}
        onClose={newNote => {
          setQualityNote({ characteristicsId: characteristic.qualityCharacteristicId, note: newNote, serialNumberId: characteristic.serialnumberId })
          controller.close()
        }}
      />
    </>
  )
}

export default memo(QualityCheckCard)

const styles = StyleSheet.create({
  container: {
    margin: 6,
    justifyContent: 'center',
  },
  header: {
    justifyContent: 'space-between',
  },
  serialNumber: {
    paddingTop: 2,
  },
  pressable: {
    padding: 0,
    paddingHorizontal: 6,
  },
  addNoteIconContainer: { alignItems: 'flex-start', paddingHorizontal: 8 },
})
