import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ModalController, ThemeButton, ThemeCard, ThemeChip, ThemeColorsType, ThemePressable } from '@infominds/react-native-components'
import { InfoboxExtended } from '@infominds/react-native-media'
import { Dispatch, SetStateAction } from 'react'

import {
  AccessData,
  ActivitySparePart,
  ActivityTime,
  ArticleSparePart,
  Contact,
  Destination,
  File,
  PlanningTime,
  SerialNumberCounter,
  SMNote,
} from './apis/types/apiResponseTypes'
import { DetailHeaderType } from './components/screen/types'
import { AccessDataPermission } from './contexts/AccessDataFilterContext'

export interface ThemeColorExpanded extends ThemeColorsType {
  access: { granted: string; inactive: string; denied: string }
  article: {
    status: {
      red: string
      yellow: string
      green: string
      grey: string
    }
    image: {
      placeholder: string
    }
  }
  button: ThemeButtonExtended
  card: ThemeCardExtended
  chip: ThemeChipExtended
  closing: {
    first: string
    second: string
    third: string
    fourth: string
  }
  drag: {
    active: string
  }
  dropdown: { arrow: string }
  general: {
    info: string
    error: string
    warn: string
    word: string
    excel: string
    pdf: string
    image: string
    text: string
    blue: string
  }
  header: {
    main: {
      background: string
      error: string
      text: { primary: string; secondary: string }
    }
    detail: {
      background: string
      skeletonBackground: string
    }
  }
  infobox: InfoboxExtended & {
    disableButtonBackground: string
    disableButtonIcon: string
  }
  inputBox: {
    border: { active: string; disabled: string; error: string }
    background: { active: string; disabled: string }
  }
  item: { selected: string }
  loader: {
    background: string
    border: string
  }
  map: {
    marker: {
      background: string
      highlight: string
    }
  }
  moreSheet: {
    background: string
    navigationElement: {
      background: string
      selected: string
    }
    indicator: string
  }
  pressable: ThemePressableExtended
  pressableIcon: {
    disabled: string
  }
  radioButton: string
  settings: {
    badge: {
      background: string
    }
  }
  timeline: {
    background: string
  }
  drawerNavigator: {
    background: string
    bar: string
    hover: string
    icon: {
      focused: string
      unFocused: string
    }
  }
  sync: {
    payloadBackground: string
  }
  separator: string
}

interface ThemePressableExtended extends ThemePressable {
  highlight: string
  animatedHighlight: string
}

interface ThemeChipExtended extends ThemeChip {
  border: string
  active: {
    border: string
    background: string
    text: string
  }
  icon: {
    enabled: string
    disabled: string
  }
  installation: string
  removal: string
}

interface ThemeCardExtended extends ThemeCard {
  cardBackground: {
    active: string
  }
  accent: {
    active: string
  }
  button: {
    active: string
    inactive: string
    icon: {
      active: string
      inactive: string
    }
  }
}

interface ThemeButtonExtended extends ThemeButton {
  background: string
  disabledBackground: string
  icon: string
  disabledIcon: string
  qualityCheck: string
  tertiaryButton: string
}

export type ScreenStatus = { type: DetailHeaderType; open: boolean }

export type ScreenHeight = {
  detail: number
  header: number
}

export type MenuPreferenceStorage = {
  id: number
  name: string
}

export type LoadingType = 'reloading' | 'aborted' | 'init' | 'catched' | false

export type InfiniteLoadingType = LoadingType | 'loadMore'

export type UploadStatus = 'waiting' | 'waitingWithoutPending' | 'uploading' | 'done' | 'mandatoryMissing'

export type FilterEmitter<F, G, O> = {
  filters: Filter<F>[]
  groups: Group<G>[]
  orders: Order<O>[]
}

export type SparePartsFilterEmitter<F, O> = Omit<FilterEmitter<F, void, O>, 'groups'>

export type QualityCheckFilterEmitter<G, O> = Omit<FilterEmitter<void, G, O>, 'filters'>

export type AccessDataFilterEmitter = {
  filters: AccessDataFilter[]
  permissions: AccessDataPermission[]
}

export type VaultLockType = 'vault-locked' | 'vault-unlocked'

export type CreateOrEditMode = 'creation' | 'edit'

export type ListSection<T> = {
  title?: string
  data: T[]
}

export type DividerType = 'left' | 'right'

export type ModalScreenProps<T> = {
  controller?: ModalController<T>
}

export interface ModalScreenRef {
  handleGoBack: () => void
}

export interface InfoboxScreenRef {
  handleGoBack: () => void
}

export interface EditOrCreateViewRef {
  handleUpload: () => void
}

export interface TicketReportViewRef {
  sendReport: () => void
}

export interface ClassificationsTextInputRef {
  getState: () => ClassificationValue[]
}

export interface AdditionalFieldTextInputRef {
  getState: () => AddFieldValue[]
  isMandatoryMissing: () => boolean
}

export interface TextInputRef {
  focus: () => void
  blur: () => void
}

export interface NumberInputRef extends TextInputRef {
  reset: () => void
}

export type EditOrCreateViewProps = {
  disabled?: boolean
  onUploadStatus: (status: UploadStatus) => void
}

export interface CustomerDetailsViewRef {
  checkOpeningHoursEdit: (callback?: () => void) => void
}

export interface ContactViewRef {
  openModal: () => void
}

export interface DestinationViewRef {
  openModal: () => void
}

export interface TimeButtonRef {
  reset: (status: 'stopped' | 'started') => void
}

export interface SparePartShoppingCartRef {
  upload: () => void
}

export interface SignaturePadRef {
  readSignature: () => void
  clearSignature: () => void
}

export interface ActivityViewRef {
  reset: () => void
  reactivate: () => void
  getCollaboratorId: () => string | undefined
  closing: () => void
  getUnitUp: () => ActivityUnitUp | undefined
  descriptionRestore: () => void
}

export interface SparePartCardRef {
  reset: () => void
}

export interface TextExpandableRef {
  reset: () => void
}

export interface DateInputRef {
  reset: (date: Date) => void
}

export type Border = 'top' | 'bottom'

export enum Gender {
  None = 'None',
  Male = 'Male',
  Female = 'Female',
  Neuter = 'Neuter',
}

export type FastAction = {
  disableFastAction?: boolean
  onActionPress?: (index: number) => void
}

export type NoFastAction = {
  disableFastAction?: never
  onActionPress?: never
}

export type FiniteLoadList = {
  allDataLoaded?: never
  onLoadMore?: never
}

export type InfiniteLoadList = {
  allDataLoaded: boolean
  onLoadMore: () => void
}

export enum FolderRight {
  read = 'ReadOnly',
  editLimited = 'EditOnlyOwnDocuments',
  write = 'AllRights',
  notVisible = 'NotVisible',
  none = 'None',
}

export enum InfoboxFolderVisibilityType {
  None = 'None',
  Article = 'Article',
  SMArticle = 'SMArticle',
  Customer = 'Customer',
  Offer = 'Offer',
  Ticket = 'Ticket',
  Activity = 'Activity',
  Order = 'Order',
  DeliveryAddress = 'DeliveryAddress',
  Call = 'Call',
  PurchaseOrder = 'PurchaseOrder',
  Purchase = 'Purchase',
  SafetyDataSheet = 'SafetyDataSheet',
  Supplier = 'Supplier',
  PurchaseOrdermovement = 'PurchaseOrdermovement',
  PurchaseBillofDelivery = 'PurchaseBillofDelivery',
  CustomerNote = 'CustomerNote',
  Serialnumber = 'Serialnumber',
  Tasks = 'Tasks',
  OrderPartlist = 'OrderPartlist',
  Ordermovement = 'Ordermovement',
  ProjectCheckListDetail = 'ProjectCheckListDetail',
  Sales = 'Sales',
  Producer = 'Producer',
  Classification = 'Classification',
  CorrespondenceAddress = 'CorrespondenceAddress',
  CustomersContract = 'CustomersContract',
  ExpensesMovement = 'ExpensesMovement',
  Employee = 'Employee',
  Project = 'Project',
  Production = 'Production',
}

export type ClassificationType =
  | 'None'
  | 'Customer'
  | 'Supplier'
  | 'Article'
  | 'Employee'
  | 'CorrespondezAddress'
  | 'InvestmentGoods'
  | 'Project'
  | 'MaterialProcessing'
  | 'ProcessingType'
  | 'Agent'
  | 'Resource'
  | 'Campaigns'
  | 'Complaint'
  | 'Knowlegdebase'
  | 'Ticket'
  | 'Serialnumber'
  | 'ServicemanagementContract'
  | 'Contact'
  | 'PrecalculationArticle'
  | 'Losses'
  | 'Qualitycontrol'
  | 'Promotion'
  | 'BonusContract'
  | 'Expenses'
  | 'Address'
  | 'SalesOpportunity'
  | 'BlanketOrder'
  | 'MaterialBlocking'
  | 'AccountingKeyPresencetime'

export type AdditionalFieldType =
  | 'None'
  | 'Customer'
  | 'Supplier'
  | 'Article'
  | 'Employee'
  | 'ShippingAddress'
  | 'Ticket'
  | 'CorrespondenceAddress'
  | 'Serialnumber'
  | 'Contact'
  | 'Expenses'
  | 'Activity'

export type State =
  | 'None'
  | 'Request'
  | 'Plan'
  | 'Editing'
  | 'Done'
  | 'Blocked'
  | 'ToBuffer'
  | 'NormalBilling'
  | 'NormalNoBilling'
  | 'Inspectation'
  | 'AfterVisitation'
  | 'WorkReport'
  | 'Allocation'
  | 'Reactivation'
  | 'UserFilterActivitiesToPlanning'

export type FieldType = 'None' | 'Text' | 'Numeric' | 'Date' | 'Boolean' | 'ForeignKey' | 'TextHTML' | 'TextRTF' | 'Selection'

export type SerialNumberState = 'Red' | 'Green' | 'Yellow' | 'None'

export type ActivityType = 'None' | 'Hotline' | 'RemoteService' | 'EMail' | 'Internal' | 'External'

export type PasswordComplexity = 'None' | 'Simple' | 'Medium' | 'Advanced' | 'Custom'

export type ContactEditOrCreateParams = { addressId: string; contact?: Contact }

export type DestinationEditOrCreateParams = { customerId: string; destination?: Destination }

export type AccessDataEditOrCreateParams = { customerId: string; accessData?: AccessData }

export type NoteEditOrCreateParams = { type: SMNoteType; id: string; note?: SMNote }

export type TicketArticleCounterParams = { counters: SerialNumberCounter[]; focus: number; activityId: string | undefined }

export type ActivityArticleCounterParams = { counters: SerialNumberCounter[]; emitId?: string; activityId: string }

export type TicketReportParams = { ticketId: string }

export type TicketAddSNParams = { customerId: string; ticketId: string; shippingAddressId: string | undefined }

export type TicketEditPriorityParams = { priorityId: string | undefined; ticketId: string }

export type TicketCreateActivityParams = { ticketId: string }

export type EditStateParams = { stateId: string | undefined; id: string; type: 'Ticket' | 'Activity' }

export type EditInvoiceTypeParams = { invoiceTypeId: string | undefined; id: string; type: 'Ticket' | 'Activity' }

export type AdditionalFieldsParams = { id: string; type: AdditionalFieldType; values: AdditionalField[] }

export type ClassificationParams = { id: string; type: ClassificationType; values: TicketClassification[] }

export type ActivityEditOrCreateTimesParams = { activityId: string; time?: ActivityTime }

export type ActivityEditOrCreatePlanningTimesParams = { time?: PlanningTime; ticketId: string; activityId: string }

export type ActivityPurchaseOrderParams = { ticketId: string }

export type ActivityUnitUpParams = { activityId: string; activityUnitUp: ActivityUnitUp | undefined }

export type ActivityAllocationParams = {
  activityIds: string[]
  employeeId: string | undefined
  origin: 'ticketActivity' | 'planningActivity' | 'planningList'
  ticketId?: string
  ticketCode?: string
}

export type ActivitySendReportParams = { activityId: string; init?: string }

export type InfoboxMediaParams = {
  id: string
  type: InfoboxType
  folderNumber: number
  folderRight: FolderRight
  description?: string
  screenTitle?: string
  masterDetailFiles?: File[]
  forceMasterDetail?: boolean
}

export type InfoboxFoldersParams = {
  id: string
  type: InfoboxType
  subTitle?: string
}

export type InfoboxType =
  | 'None'
  | 'Article'
  | 'SMArticle'
  | 'Customer'
  | 'Offer'
  | 'Ticket'
  | 'Activity'
  | 'Order'
  | 'DeliveryAddress'
  | 'Call'
  | 'PurchaseOrder'
  | 'Purchase'
  | 'SafetyDataSheet'
  | 'Supplier'
  | 'PurchaseOrdermovement'
  | 'PurchaseBillofDelivery'
  | 'CustomerNote'
  | 'Serialnumber'
  | 'Tasks'
  | 'OrderPartlist'
  | 'Ordermovement'
  | 'ProjectCheckListDetail'
  | 'Sales'
  | 'Producer'
  | 'Classification'
  | 'CorrespondenceAddress'
  | 'CustomersContract'
  | 'ExpensesMovement'
  | 'Employee'
  | 'Project'
  | 'Production'
  | 'SmNote'

export type PriorityType = 'Low' | 'Normal' | 'High'

export type IconType = {
  type: ActivityType
  icon: IconProp
}

export type MenuItems = {
  tickets: boolean
  passwordmanagement: boolean
  activitiesPlaning: boolean
  activitiesHistory: boolean
  spareParts: boolean
  qualititymanagement: boolean
  synchronisation: boolean
}

export interface FilterInfo {
  name: string
  id: string
}

export interface AccessDataFilter {
  data: FilterInfo
  active: boolean
}

export interface Group<T> {
  data: {
    name: string
    id: T
  }
  active: boolean
}

export interface Order<T> {
  data: {
    icon?: IconProp
    name: string
    id: T
  }
  active: boolean
}

export interface Filter<T> {
  id: T
  name: string
  elements: FilterElement[]
}

export type StorageType = {
  id: string
  value: string | boolean
}

export type FilterStorageType<T> = {
  id: T
  data: StorageType[]
}

export interface FilterElement {
  id: string
  description?: string
  value?: string // used for Plan Date
  active: boolean
}

export interface ActivityEmployee {
  employeeId: string
  employee: string
}

export type AddFieldValue = { definitionId: string; value?: string }

export interface ClassificationValue {
  classificationId?: string
  classificationDefinitionId?: string
  value?: string
}

export type ActivityEmail = {
  /** @param id it has no value when the app is online. It is here only because it is needed by data provider */
  id: string
  description: string
  email: string
  employeeId: string
  employeeFunction?: string
}

export type ActivityState =
  | 'None'
  | 'Request'
  | 'Plan'
  | 'Editing'
  | 'Done'
  | 'Blocked'
  | 'ToBuffer'
  | 'NormalBilling'
  | 'NormalNoBilling'
  | 'Inspectation'
  | 'AfterVisitation'
  | 'WorkReport'
  | 'Allocation'
  | 'Reactivation'
  | 'Closing'
  | 'ClosingWithVerification'
  | 'ClosingWithAFollowupVisit'
  | 'ResetInPlanning'
  | 'UserFilterActivitiesToPlanning'

export type TabType = 'contacts' | 'destinations'

export type NoteType = 'None' | 'Customer' | 'Offer' | 'Order' | 'Reclamation' | 'CorrespondenceAddress' | 'Supplier' | 'Ticket' | 'Activity'

export type SMNoteType = 'None' | 'Ticket' | 'Activity' | 'Call'

export type ApiError = {
  Message: string
  code: string
}

export type AdditionalField = {
  id?: string
  definitionId: string
  definitionCode: string
  definitionDescription: string
  definitionFeldType: FieldType
  definitionIsMandatory: boolean
  definitionFieldDecimal: number
  definitionFieldLength: number
  stringValue?: string
  dateValue?: string
  numberValue?: number
  logicValue?: boolean
  description?: string
}

export type Classification = {
  id?: string
  definitionId: string
  definitionCode: string
  definitionDescription: string
  type: ClassificationType
  classificationId?: string
  classificationCode?: string
  classificationDescription?: string
}

export type TicketClassification = {
  id?: string
  definitionId: string
  definitionCode: string
  definitionDescription: string
  definitionIsMandatory: boolean
  type: ClassificationType
  classificationId?: string
  classificationCode?: string
  classificationDescription?: string
}

export type MonitoringType =
  | 'None'
  | 'NetAnalyzer'
  | 'MPSMonitor'
  | 'Jetadvice'
  | 'Printfleet'
  | 'Xerox'
  | 'FMAudit'
  | 'Docuform'
  | 'RemoteView'
  | 'CSRemote'
  | 'SimpleClicks'
  | 'Unknown'

export type CounterKindType =
  | 'None'
  | 'TotalBlack'
  | 'TotalColor'
  | 'TotalScan'
  | 'CyclesTotal'
  | 'CyclesBlack'
  | 'CyclesColor'
  | 'Total'
  | 'CopierBlack'
  | 'CopierBlackA4'
  | 'CopierBlackA3'
  | 'PrinterBlack'
  | 'PrinterBlackA4'
  | 'PrinterBlackA3'
  | 'CopierColor'
  | 'CopierColorA4 '
  | 'CopierColorA3'
  | 'PrinterColor'
  | 'PrinterColorA4'
  | 'PrinterColorA3'
  | 'TotalA3'
  | 'TotalA4'
  | 'TotalScannerBlack'
  | 'TotalScannerColor'
  | 'TotalBlackA4'
  | 'TotalBlackA3'
  | 'TotalColorA4'
  | 'TotalColorA3'
  | 'ScannerA4'
  | 'ScannerA4Black'
  | 'ScannerA4Color'
  | 'ScannerA3'
  | 'ScannerA3Black'
  | 'ScannerA3Color'
  | 'TotalDuplex'
  | 'DuplexA4'
  | 'DuplexA3'
  | 'DuplexA4Black'
  | 'DuplexA3Black'
  | 'DuplexA4Color'
  | 'DuplexA3Color'
  | 'Color1A4'
  | 'Color2A4'
  | 'Color3A4'
  | 'Color1A3'
  | 'Color2A3'
  | 'Color3A3'
  | 'Color1'
  | 'Color2'
  | 'Color3'
  | 'Meter'
  | 'BerichteBlack'
  | 'BerichteColor'
  | 'TotalFax'
  | 'BlackColor'
  | 'ColorC1'
  | 'ColorC2'
  | 'BlackLevel1Impressions'
  | 'BlackLevel2Impressions'
  | 'BlackLevel3Impressions'
  | 'ColorLevel1Impressions'
  | 'ColorLevel2Impressions'
  | 'ColorLevel3Impressions'
  | 'BlackundColorLevel1Impressions'
  | 'BiancoNeroundColoreUtile'
  | 'Coloreespressivo'
  | 'Colorequotidiano'
  | 'Lowcoveragefullcolortotalcount'
  | 'Middlecoveragefullcolortotalcount'
  | 'Highcoveragefullcolortotalcount'
  | 'ColorLevel1'
  | 'ColorLevel2'
  | 'ColorLevel3'
  | 'ScanOther'
  | 'DoubleClickMono'
  | 'DoubleClickColor'
  | 'TotalformatcounterMono'
  | 'TotalformatcounterFullColor'
  | 'Totalfullcolor'
  | 'Totallowcolor'
  | 'TotalPrinter'
  | 'T106TotalVollfarbe2'
  | 'T109TotalSchwarz2'
  | 'T112TotalSchwarzoderGroß'
  | 'T113TotalSchwarzoderKlein'
  | 'T122TotalVollfarbeundEinzelfarbeGroß'
  | 'T123TotalVollfarbeundEinzelfarbeKlein'
  | 'T124TotalVollfarbeundEinzelfarbe2'
  | 'T501ScanTotal1'
  | 'UsagePagesMono'
  | 'UsagePagesColor'
  | 'GeneralOfficeAccentCopy'
  | 'GeneralOfficeAccentPrint'
  | 'GeneralOfficeAccentFax'
  | 'MAXDPIAccentCopy'
  | 'MAXDPIAccentPrint'
  | 'MAXDPIAccentFax'
  | 'PresentationAccentCopy'
  | 'PresentationAccentPrint'
  | 'PresentationAccentFAX'
  | 'ProfessionalAccentCopy'
  | 'ProfessionalAccentPrint'
  | 'ProfessionalAccentFAX'
  | 'ScansNet'
  | 'Totallongsheet'
  | 'TotalfourcolorLongsheet'
  | 'TotalblackandwhiteLarge'
  | 'TotalblackandwhiteSmall'
  | 'TotalfullcolorundmonocolorLarge'
  | 'TotalfullcolorundmonocolorSmall'
  | 'Druckseiten'
  | 'Level1Pages'
  | 'Level2Pages'
  | 'Level3Pages'
  | 'ManuellesMapping'

export type Description = {
  number: number
  language: 'DE' | 'IT' | 'EN'
  text: string
}

export type MeasurementUnit = {
  id: string
  code?: string
  type: MeasurementUnitType
  conversation?: number
  netWeight?: number
}

export type MeasurementUnitType = 'None' | 'Sales' | 'Purchasing' | 'SalesPurchasing'

export type Price = {
  id: string
  articleId?: string
  customerId?: string
  currency?: string
  companyId?: string
  pricekey?: string
  validFrom?: string
  validUntil?: string
  amount?: number
  surchargeDebit?: number
  unit?: number
  rounding?: number
  option1?: string
  option1Logic?: boolean
  option1Date?: string
  dependancytype: PriceDependencyType
  dependancyPricekey?: string
}

export type PriceDependencyType = 'NoDependancy' | 'Discount' | 'ExtraCharge' | 'MarginOfProfit' | 'AbsoluteDifference' | 'DiscountOfDependancyPrice'

export type ArticleSheet = {
  articlesheetsdefinitionId: string
  articlesheetsdefinitionCode?: string
  language?: string
  text?: string
}

export type IntrastatMovingType = 'FromClassOfGoods' | 'Products' | 'Service' | 'NoIntra'

export type ArticleType =
  | 'None'
  | 'CommercialArticle'
  | 'ProductionArticle'
  | 'ArticleToBeOrdered'
  | 'SemifinishedProduct'
  | 'PrimaryProduct'
  | 'FictivePart'

export type CreateSerialNumberType = 'No' | 'AutomaticallyPerArticle' | 'AutomaticallyGlobal' | 'ConfigurationSerialnumber' | 'FixedSerialnumber'

export type AvailabilityControlType = 'Default' | 'Blocking' | 'Warning' | 'NoControl'

export const readingTypes = [
  'None',
  'Manual',
  'StartCounter',
  'Activity',
  'TheoreticalCounterStatus',
  'Telematic',
  'Monitoring',
  'Calculated',
] as const

export type ReadingType = (typeof readingTypes)[number]

export type SelectorReadingType = { id: ReadingType; value: string }

export type CustomerLanguage = 'Undefined' | 'German' | 'Italian' | 'English'

export enum TimeFormat {
  TIME = 'time',
  TIME_WITH_DIMENSIONS = 'timeWithDimensions',
  COUNTER = 'counter',
}

export enum CloseType {
  ok = 'CLOSE_STANDARD',
  withCheck = 'CLOSE_WITH_CHECK',
  withNextActivity = 'CLOSE_WITH_NEW_ACTIVITY',
}

export type CloseActivityEvent<T = object> = {
  done: boolean
  pending: boolean
  dto: T
}

export type ClosingDto = {
  activityIds?: ActivityToClose[]
  reportEmails?: string[]
  signatureImageData?: string
  signaturePenColor?: string
  signatureName?: string
  nextActivityTechnicalDescription?: string
  nextActivityEmployeeId?: string
}

export type ActivityToClose = { id: string; mandatory: boolean }

export type ActivityViewType = 'normal' | 'history' | 'planning'

export type SparePartDirection = 'Installation' | 'Removed'

export type PartDto = Pick<
  ArticleSparePart,
  | 'articleId'
  | 'articleCode'
  | 'articleModel'
  | 'serialnumber'
  | 'serialnumberId'
  | 'articleSearchtext'
  | 'measurementUnit'
  | 'measurementDecimals'
  | 'articleDescription'
  | 'installationDate'
  | 'isDummy'
  | 'depositId'
  | 'depositCode'
  | 'depositDescription'
  | 'removalDate'
  | 'preCalcQuantity'
> &
  Pick<
    ActivitySparePart,
    | 'id'
    | 'invoicingType'
    | 'invoicingTypeId'
    | 'date'
    | 'price'
    | 'isSerialnumberActive'
    | 'isLottoSerialnumberActive'
    | 'isWarehouseMovementActive'
    | 'serialnumberParent'
    | 'serialnumberIdParent'
    | 'articleSearchtextParent'
    | 'serialnumberNumberManufactorParent'
    | 'direction'
    | 'quantity'
    | 'stockQuantity'
    | 'description'
  > & {
    newDescription?: string
    pickedPrice?: number
    initQuantity?: number
    initSerialnumberIdParent?: string
    prevStatus: PartStatus
    status: PartStatus
  }

export type PartStatus = 'init' | 'deleted' | 'edited' | 'mandatoryMissing' | 'new'

export type PartsManagementView = 'spareParts' | 'shoppingCart'

export interface DateTimePickerProps {
  date: Date
  show: boolean
  mode: 'date' | 'time'
  minimumDate?: Date
  maximumDate?: Date
  setDate: (newDate: Date) => void
  setShow: Dispatch<SetStateAction<boolean>>
}

export type UnitUp = {
  unitUp: boolean
  time?: number
  date?: string
}

export type ActivityUnitUp = {
  unitUp: boolean
  dateTime?: string
}

export type ApiStateType =
  | 'init'
  | 'posting'
  | 'posted'
  | 'failed-posting'
  | 'patching'
  | 'patched'
  | 'failed-patching'
  | 'loading'
  | 'loaded'
  | 'failed-loading'

export type ActivityTimeState = TimeState | 'stopping-travel-time' | 'stopping-other-activity'

export type TravelTimeState = TimeState | 'stopping-activity-time'

type TimeState = 'init' | 'checking-start' | 'checking-stop' | 'checking' | 'starting' | 'started' | 'stopping' | 'stopped' | 'catched'

export type Characteristic = {
  qualityCharacteristicId: string
  qualityCharacteristicCode: string
  qualityCharacteristicDescription: string
  qualityCharacteristicFieldTyp: QualityCheckType
  qualityCharacteristicLength: number
  qualityCharacteristicDecimal: number
  qualityCharacteristicOrder: number
  qualityCharacteristicDetails?: QualityCharacteristicDetails[]
  qualityCharacteristicCheck?: QualityCharacteristicCheck
  note?: string
  value?: string
}

export type CharacteristicsModified = Characteristic & {
  id: string
  serialnumberId: string
  serialnumber: string
  serialnumberManufacturer?: string
  articleDescription: string
  activityId: string
  serialnumberSortkey: number
}

export type QualityCheckType = 'None' | 'Text' | 'Numeric' | 'Boolean' | 'Selection'

export type QualityCharacteristicCheck = {
  isMandatory: boolean
  targetBoolean: boolean
  targetText?: string
  targetSelectionDetailId?: string
  targetNumericFrom: number
  targetNumericUntil?: number
  targetCompare: boolean
}

export type QualityCharacteristicDetails = {
  id: string
  code: string
  description: string
}

export type QualityPost = {
  id: string
  activityId: string
  serialnumberId: string
  values: QualityValue[]
}

export type QualityValue = {
  qualityCharacteristicId?: string
  value: string | undefined
  note?: string
}

export type PurchaseOrderMovement = {
  articleid: string
  quantity: number
  note?: string
}

export type StockQuantityType = {
  articleId: string
  depositId: string
  serialnumberId: string | undefined
  stockQuantity: number | undefined
  pickedQuantity: number | undefined
}

export type InfoboxInfoEvent = {
  id: string
  infoboxTyp: InfoboxType
  note: string | undefined
  filename: string | undefined
}

export type SynchronisationInfo = {
  deviceId: string
  synchronisationDetails: SynchronisationDetail[]
}

export type SynchronisationDetail = {
  type: string // Employee, Ticket,..
  dataCount: number
  blockCount: number
  startWithBlock: number
  fullSync: boolean
}

export type SynchronisationData = {
  deviceId: string
  type: string
  blockNumber: number
  data?: object[] | object
}

export const ClosedActivityStates: ActivityState[] = ['Closing', 'ClosingWithAFollowupVisit', 'ClosingWithVerification']
