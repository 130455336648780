import { IM, IMStyle, SpacingProps, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import useRequest from '../../components/Infominds/hooks/useRequest'
import { DescriptionInput } from '../../components/input/DescriptionInput'
import { REFRESH_TICKET_DETAIL_EVENT_KEY } from '../../constants/EmitterKeys'
import useUserSettings from '../../hooks/useUserSettings'
import EditDescriptionModal, { EditDescriptionModalType } from '../../modals/EditDescriptionModal'

type Props = {
  ticketId: string
  description: string
  tecDescription: string | undefined
  customerDescription: string | undefined
  spacing?: SpacingProps
}

enum ModalType {
  TECHNICAL = 'Technical',
  CUSTOMER = 'Customer',
}

const TicketDetailDescriptionCard = memo(function TicketDetailDescriptionCard({
  ticketId,
  description,
  tecDescription,
  customerDescription,
  spacing,
}: Props) {
  const { i18n } = useLanguage()
  const { userSettings } = useUserSettings()
  const controller = useModalController<EditDescriptionModalType>()
  const { emit } = useEvent({ key: REFRESH_TICKET_DETAIL_EVENT_KEY })

  const { request, reset, loading, error } = useRequest(api.editTicket, {
    disableAlert: true,
    onResult: () => emit(),
  })

  return (
    <>
      <IM.Card head={<CardLeftTitle text={i18n.t('DESCRIPTION')} />} spacing={spacing}>
        <IM.View spacing={tecDescription ? 'bottom' : 'none'}>
          <IM.Text>{description}</IM.Text>
        </IM.View>
        {userSettings?.allowModifyTicketCustomerAndTechnicalDescriptions ? (
          <DescriptionInput
            title={i18n.t('TECHNICAL_DESCRIPTION')}
            value={tecDescription ?? ''}
            onPress={() => controller.show({ title: i18n.t('EDIT_TECHNICAL_DESCRIPTION'), value: tecDescription, id: ModalType.TECHNICAL })}
          />
        ) : (
          <>
            {!!tecDescription && (
              <>
                <IM.Text style={styles.title}>{i18n.t('TECHNICAL_DESCRIPTION')}</IM.Text>
                <IM.Text>{tecDescription}</IM.Text>
              </>
            )}
          </>
        )}
        {userSettings?.allowModifyTicketCustomerAndTechnicalDescriptions ? (
          <DescriptionInput
            title={i18n.t('CUSTOMER_DESCRIPTION')}
            value={customerDescription ?? ''}
            onPress={() => controller.show({ title: i18n.t('EDIT_CUSTOMER_DESCRIPTION'), value: customerDescription, id: ModalType.CUSTOMER })}
          />
        ) : (
          <>
            {!!customerDescription && (
              <IM.View spacing="top">
                <IM.Text style={styles.title}>{i18n.t('CUSTOMER_DESCRIPTION')}</IM.Text>
                <IM.Text>{customerDescription}</IM.Text>
              </IM.View>
            )}
          </>
        )}
      </IM.Card>
      <EditDescriptionModal
        controller={controller}
        onSave={(value, id) => {
          switch (id) {
            case ModalType.TECHNICAL:
              request({
                id: ticketId,
                technicalDescription: value ?? '',
              })
              break
            case ModalType.CUSTOMER:
              request({
                id: ticketId,
                customerDescription: value ?? '',
              })
              break
          }
        }}
        onClose={reset}
        loading={loading !== false}
        error={error}
      />
    </>
  )
})

export default TicketDetailDescriptionCard

const styles = StyleSheet.create({
  title: {
    fontSize: IMStyle.typography.fontSizeMedium,
    fontWeight: IMStyle.typography.fontWeightBold,
  },
})
