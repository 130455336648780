import { CardProps, IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { createRef, Dispatch, ForwardedRef, forwardRef, memo, SetStateAction, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { useRecoilState } from 'recoil'

import { InvoiceType, TicketArticle } from '../../apis/types/apiResponseTypes'
import Button from '../../components/Button'
import Pressable from '../../components/Infominds/Pressable'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import DateInput, { convertDateToInputFormat } from '../../components/input/DateInput'
import TextInput from '../../components/input/TextInput'
import useSearch from '../../components/screen/hooks/useSearch'
import ArticleSelector from '../../components/selectors/ArticleSelector'
import ArticleSerialNumberSelector from '../../components/selectors/ArticleSerialNumberSelector'
import InvoiceTypeSelector from '../../components/selectors/InvoiceTypeSelector'
import SparePartQuantityInput from '../../components/SparePartQuantityInput'
import SparePartsPrice from '../../components/SparePartsPrice'
import Text from '../../components/Text'
import TextExpandable from '../../components/TextExpandable'
import useIsOnline from '../../dataProvider/hooks/useIsOnline'
import useUserSettings from '../../hooks/useUserSettings'
import {
  DateInputRef,
  PartDto,
  PartsManagementView,
  SparePartCardRef,
  SparePartDirection,
  StockQuantityType,
  TextExpandableRef,
  ThemeColorExpanded,
} from '../../types'
import appUtils from '../../utils/appUtils'
import { numberUtils } from '../../utils/numberUtils'
import { stockQuantityAtom } from '../../utils/stateManager'
import ticketUtils from '../../utils/TicketUtils'
import TimeUtils from '../../utils/TimeUtils'
import { utils } from '../../utils/utils'

type Props = CardProps & {
  activityId: string
  shown: boolean
  articles: TicketArticle[]
  invoices: InvoiceType[]
  dto: PartDto
  type: PartsManagementView
  disableButton?: boolean
  changesPending?: boolean
  direction: SparePartDirection
  onConfirm?: () => void
  onChangeDto: Dispatch<SetStateAction<PartDto>>
  onChangeShown: Dispatch<SetStateAction<boolean>>
  setDefaultInvoiceTypeId: Dispatch<SetStateAction<string | undefined>>
}

const SparePartsCard = memo(
  forwardRef(function SparePartsCard(
    { direction, activityId, shown, articles, invoices, dto, type, disableButton, changesPending, setDefaultInvoiceTypeId, ...others }: Props,
    ref: ForwardedRef<SparePartCardRef>
  ) {
    useImperativeHandle(ref, () => ({
      reset: () => {
        textRef.current?.reset()
        selectorRef.current?.reset()
        setKey(utils.generateUuid())
        resetPrice()
      },
    }))

    const { onConfirm, onChangeDto, onChangeShown } = others

    const isOnline = useIsOnline()
    const { i18n, language } = useLanguage()
    const { userSettings } = useUserSettings()
    const { qrCodeScanned } = useSearch()
    const { theme } = useTheme<ThemeColorExpanded>()

    const textRef = createRef<TextExpandableRef>()
    const selectorRef = createRef<SparePartCardRef>()
    const dateInputRef = createRef<DateInputRef>()

    const [key, setKey] = useState(utils.generateUuid())
    const [price, setPrice] = useState<string | undefined>()
    const [defaultPrice, setDefaultPrice] = useState<string | undefined>()
    const [commonStockQuantity, setCommonStockQuantity] = useRecoilState(stockQuantityAtom)

    useEffect(() => {
      if (shown || type === 'shoppingCart') return

      resetPrice()
    }, [shown, type])

    useEffect(() => {
      qrCodeScanned && handleChange({ quantity: 1 })
    }, [qrCodeScanned])

    const resetPrice = () => {
      setPrice(defaultPrice)
      handleChange({ pickedPrice: defaultPrice ? transformLocalizedPrice(defaultPrice) : undefined })
    }

    const handleChange = (newVal: Partial<PartDto>) => {
      onChangeDto(prev => {
        const value = {
          ...prev,
          ...newVal,
        }

        value.status = ticketUtils.getSparePartStatus(value)

        return value
      })
    }

    const handleQuantityInputChange = (newValue: string) => {
      const value = parseFloat(newValue)

      if (type === 'shoppingCart' && value !== undefined) {
        const clone = cloneDeep(commonStockQuantity)

        if (clone) {
          const foundIndx = clone?.findIndex(quantityPredicate)
          const quantityChange = value - (dto.quantity ?? 0)

          if (foundIndx !== -1) {
            clone[foundIndx].pickedQuantity = stockQuantity.picked + quantityChange
          } else {
            clone.push({
              articleId: dto.articleId,
              depositId: dto.depositId,
              stockQuantity: dto.stockQuantity,
              pickedQuantity: stockQuantity.picked + quantityChange,
              serialnumberId: dto.serialnumberId,
            })
          }
          setCommonStockQuantity(clone)
        }
      }

      handleChange({ quantity: value })
    }

    const formatDepositString = <T extends { depositCode?: string; depositDescription?: string }>(elem: T) => {
      if (elem.depositCode && elem.depositDescription) return `${elem.depositCode} - ${elem.depositDescription}`

      return elem.depositCode ?? elem.depositDescription
    }

    const getStockQuantityMax = () => {
      if (dto.isSerialnumberActive && !dto.isLottoSerialnumberActive) {
        return 1
      }

      if (stockQuantity.stock === undefined || !dto.isWarehouseMovementActive) return undefined

      return stockQuantity.stock + (dto.initQuantity ?? 0) - stockQuantity.picked
    }

    const quantityPredicate = (qnt: StockQuantityType) =>
      qnt.articleId === dto.articleId && qnt.depositId === dto.depositId && qnt.serialnumberId === dto.serialnumberId

    const transformLocalizedPrice = (localizedPrice: string) => {
      if (language === 'en') {
        return parseFloat(localizedPrice.replace(',', ''))
      } else {
        return parseFloat(localizedPrice.replace('.', '').replace(',', '.'))
      }
    }

    const stockQuantity = useMemo(() => {
      let found = commonStockQuantity?.find(quantityPredicate)

      if (found === undefined) {
        found = {
          stockQuantity: dto.stockQuantity,
          articleId: dto.articleId,
          depositId: dto.depositId,
          serialnumberId: dto.serialnumberId,
          pickedQuantity: 0,
        }
      }

      return { stock: found.stockQuantity, picked: found.pickedQuantity ?? 0, initial: dto.initQuantity ?? 0 }
    }, [dto, commonStockQuantity])

    const quantityInputMax = useMemo(
      () =>
        dto.isDummy || userSettings?.checkAvailabilityOfSpareParts === false
          ? undefined
          : type === 'shoppingCart'
            ? (dto.quantity ?? 0) + (stockQuantity.stock ?? 0) - stockQuantity.picked
            : getStockQuantityMax(),
      [dto, type, stockQuantity, userSettings]
    )
    const stock = (stockQuantity.stock ?? 0) - stockQuantity.picked

    return (
      <IM.Card noContentSpacing {...others}>
        <Pressable onPress={() => onChangeShown(prev => !prev)} style={styles.pressable} disableOpacity>
          <IM.View style={IMLayout.flex.row}>
            <IM.View style={IMLayout.flex.f1}>
              <IM.Text numberOfLines={shown ? undefined : 2}>
                {dto.isDummy ? i18n.t('MANUAL_INSERTION').toUpperCase() : dto.articleDescription}
              </IM.Text>
              {/* eslint-disable-next-line react-native/no-inline-styles */}
              <Text secondary={dto.articleCode === ''} style={{ paddingTop: 4 }}>
                {dto.isDummy && dto.articleCode === ''
                  ? i18n.t('ARTICLE_CODE')
                  : dto.articleCode + (dto.articleModel !== undefined && dto.articleModel !== '' ? ' ' + `(${dto.articleModel})` : '')}
              </Text>
            </IM.View>
            <IM.View>
              <SparePartQuantityInput
                key={key}
                value={dto.quantity?.toString()}
                onChangeText={val => handleQuantityInputChange(val)}
                unity={dto.measurementUnit}
                decimals={dto.measurementDecimals}
                onPlus={() => dto.quantity === 0 && onChangeShown(true)}
                onMinus={() => dto.quantity === 1 && onChangeShown(false)}
                max={quantityInputMax}
                min={0}
                pending={type === 'shoppingCart' && changesPending}
                error={type === 'shoppingCart' ? dto.quantity === undefined : false}
                preCalculation={type === 'spareParts' ? dto.preCalcQuantity : undefined}
                // issue on iOS when we set textAlign to right! The ellipsis is not shown after input is blurred
                textAlign={Platform.OS === 'ios' ? undefined : 'right'}
              />
            </IM.View>
          </IM.View>
          <IM.View spacing="top">
            {dto.serialnumber !== undefined && (
              <TextWithIcon secondary icon={['fal', 'barcode']}>
                {dto.isDummy && dto.serialnumber === '' ? i18n.t('SERIAL_NUMBER') : dto.serialnumber}
              </TextWithIcon>
            )}
            {direction === 'Removed' && dto.installationDate !== undefined && (
              <TextWithIcon secondary icon={['fal', 'calendar-arrow-down']}>
                {TimeUtils.format(dto.installationDate, language)}
              </TextWithIcon>
            )}
            {direction === 'Removed' && dto.removalDate !== undefined && (
              <TextWithIcon secondary icon={['fal', 'calendar-arrow-up']}>
                {TimeUtils.format(dto.removalDate, language)}
              </TextWithIcon>
            )}
            {!!dto.depositId && (
              <TextWithIcon secondary icon={['fal', 'warehouse-full']}>
                {formatDepositString(dto)}
              </TextWithIcon>
            )}
            {dto.isWarehouseMovementActive && !dto.isDummy && (
              <TextWithIcon secondary icon={['fal', 'box']}>
                {(numberUtils.convertNumberToLocaleString(stock, language) ?? '') + (dto.measurementUnit ? ` ${dto.measurementUnit}` : '')}
              </TextWithIcon>
            )}
            {dto.description && (
              <IM.View spacing="top">
                <TextExpandable ref={textRef} secondary numberOfLines={2} inline>
                  {dto.description}
                </TextExpandable>
              </IM.View>
            )}
            {direction === 'Removed' && type === 'spareParts' && (
              <IM.View style={styles.button}>
                <Button
                  title={i18n.t('ADD')}
                  onPress={() => onConfirm?.()}
                  disabled={dto.quantity === 0 || dto.quantity === undefined}
                  color={theme.general.info}
                />
              </IM.View>
            )}
          </IM.View>
          {direction === 'Installation' && shown && (
            <>
              {dto.isDummy && (
                <>
                  {isOnline ? (
                    <ArticleSelector
                      ref={selectorRef}
                      code={dto.articleCode === '' ? undefined : dto.articleCode}
                      isMandatory
                      onChange={val => {
                        val
                          ? handleChange({ articleId: val.id, articleCode: val.code, articleSearchtext: val.searchtext })
                          : handleChange({ articleId: undefined, articleCode: undefined, articleSearchtext: undefined })
                      }}
                      spacing="vertical"
                    />
                  ) : (
                    <TextInput
                      title={i18n.t('ARTICLE_CODE') + ' *'}
                      value={dto.articleCode}
                      onChangeText={val => handleChange({ articleCode: val })}
                      spacing="vertical"
                    />
                  )}
                  <TextInput
                    title={i18n.t('SERIAL_NUMBER')}
                    value={dto.serialnumber}
                    onChangeText={val => handleChange({ serialnumber: val })}
                    spacing="vertical"
                  />
                </>
              )}
              {articles.length > 0 && (
                <>
                  {type === 'spareParts' ? (
                    <ArticleSerialNumberSelector
                      data={articles}
                      value={dto.serialnumberIdParent}
                      onChange={val => {
                        if (val) {
                          handleChange({
                            serialnumberIdParent: val.serialnumberId,
                            serialnumberParent: val.serialnumber,
                            serialnumberNumberManufactorParent: val.serialnumberNumberManufactor,
                            articleSearchtextParent: val.articleSearchtext,
                          })
                        } else {
                          handleChange({
                            serialnumberIdParent: undefined,
                            serialnumberParent: undefined,
                            serialnumberNumberManufactorParent: undefined,
                            articleSearchtextParent: undefined,
                          })
                        }
                      }}
                      spacing="vertical"
                    />
                  ) : (
                    <ArticleSerialNumberSelector
                      data={articles}
                      value={dto.serialnumberIdParent}
                      spacing="vertical"
                      editable={false}
                      onChange={undefined}
                    />
                  )}
                </>
              )}
              {type === 'spareParts' ? (
                <InvoiceTypeSelector
                  type="SpareParts"
                  data={invoices}
                  onChange={val => {
                    if (val) {
                      handleChange({
                        invoicingTypeId: val === undefined ? undefined : val.id,
                        invoicingType: val.description ? `${val.code} - ${val.description}` : val.code,
                      })
                    } else {
                      handleChange({ invoicingType: undefined, invoicingTypeId: undefined })
                      setPrice(undefined)
                    }
                  }}
                  onDefaultFound={setDefaultInvoiceTypeId}
                  spacing="vertical"
                  editable={type === 'spareParts'}
                />
              ) : (
                <InvoiceTypeSelector
                  type="SpareParts"
                  id={dto.invoicingTypeId}
                  data={[
                    ...invoices,
                    { id: dto.invoicingTypeId ?? '', code: dto.invoicingType ?? '', isDefault: false, isForManualTimeRecording: false },
                  ]}
                  spacing="vertical"
                  editable={false}
                  onChange={undefined}
                  onDefaultFound={undefined}
                  disableBackdropOpacity
                />
              )}
              <IM.View
                style={IMLayout.flex.row}
                spacing={userSettings?.showPriceOnRegistrationOfSpareParts || userSettings?.showDateOnRegistrationOfSpareParts ? 'vertical' : 'none'}>
                {userSettings?.showPriceOnRegistrationOfSpareParts && (
                  <IM.View style={IMLayout.flex.f1}>
                    {type === 'spareParts' ? (
                      <SparePartsPrice
                        title={i18n.t('PRICE')}
                        activityId={activityId}
                        serialnumberId={dto.serialnumberId}
                        articleId={dto.articleId}
                        value={price}
                        spacing={userSettings?.showDateOnRegistrationOfSpareParts ? 'right' : 'none'}
                        onDefaultValueFetched={formattedDefaultValue => {
                          setDefaultPrice(formattedDefaultValue)
                          setPrice(formattedDefaultValue)
                          handleChange({ price: transformLocalizedPrice(formattedDefaultValue) })
                        }}
                        onChangeText={val => {
                          setPrice(val === '' ? undefined : val)
                          handleChange({ pickedPrice: val === '' ? undefined : transformLocalizedPrice(val) })
                        }}
                        onBlur={() => setPrice(appUtils.formatPrice(dto.pickedPrice ?? 0, language))}
                      />
                    ) : (
                      <SparePartsPrice
                        title={i18n.t('PRICE')}
                        activityId={activityId}
                        serialnumberId={dto.serialnumberId}
                        articleId={dto.articleId}
                        value={appUtils.formatPrice(dto.pickedPrice ?? dto.price ?? 0, language)}
                        editable={false}
                        spacing={userSettings?.showDateOnRegistrationOfSpareParts ? 'right' : 'none'}
                        onDefaultValueFetched={undefined}
                      />
                    )}
                  </IM.View>
                )}
                {userSettings?.showDateOnRegistrationOfSpareParts && (
                  <IM.View style={IMLayout.flex.f1}>
                    {type === 'spareParts' ? (
                      <DateInput
                        ref={dateInputRef}
                        value={dto.date ? convertDateToInputFormat(new Date(dto.date), language) : undefined}
                        onChangeDate={date => handleChange({ date: date?.toISOString() })}
                        spacing={userSettings?.showPriceOnRegistrationOfSpareParts ? 'left' : 'none'}
                        title={i18n.t('DATE')}
                        maximumDate={new Date()}
                      />
                    ) : (
                      <DateInput
                        ref={dateInputRef}
                        value={dto.date ? convertDateToInputFormat(new Date(dto.date), language) : undefined}
                        spacing={userSettings?.showPriceOnRegistrationOfSpareParts ? 'left' : 'none'}
                        title={i18n.t('DATE')}
                        editable={false}
                        onChangeDate={undefined}
                      />
                    )}
                  </IM.View>
                )}
              </IM.View>
              <TextInput
                value={dto.newDescription}
                onChangeText={val => handleChange({ newDescription: val === '' ? undefined : val })}
                multiline
                spacing="vertical"
                title={i18n.t('REPLACEMENT_DESCRIPTION')}
              />
              {type === 'spareParts' && (
                <IM.View style={styles.buttonContainer}>
                  <Button
                    title={dto.isDummy ? i18n.t('CREATE') : i18n.t('ADD')}
                    onPress={() => {
                      if (onConfirm) {
                        onConfirm()
                        dateInputRef.current?.reset(new Date())
                      }
                    }}
                    color={theme.general.info}
                    disabled={disableButton}
                  />
                </IM.View>
              )}
            </>
          )}
        </Pressable>
      </IM.Card>
    )
  })
)

export default SparePartsCard

const styles = StyleSheet.create({
  button: {
    alignSelf: 'flex-end',
  },
  buttonContainer: {
    alignSelf: 'flex-end',
    paddingTop: 1,
  },
  pressable: {
    padding: 10,
  },
})
