import React, { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react'

interface ContextProps {
  pendingSignature: boolean
  onBeginSignature: VoidFunction
  onClearSignature: VoidFunction
  onSaveSignature: VoidFunction
}

export const PendingSignatureContext = createContext<ContextProps | undefined>(undefined)

export const PendingSignatureProvider = ({ children }: PropsWithChildren) => {
  const [pendingSignature, setPendingSignature] = useState(false)

  const onBeginSignature = useCallback(() => {
    setPendingSignature(true)
  }, [])

  const onClearSignature = useCallback(() => {
    setPendingSignature(false)
  }, [])

  const onSaveSignature = useCallback(() => {
    setPendingSignature(false)
  }, [])

  const contextValue: ContextProps = useMemo(
    () => ({
      pendingSignature,
      onBeginSignature,
      onClearSignature,
      onSaveSignature,
    }),
    [pendingSignature, onBeginSignature, onClearSignature, onSaveSignature]
  )

  // @ts-ignore boh
  return <PendingSignatureContext.Provider value={contextValue}>{children}</PendingSignatureContext.Provider>
}
