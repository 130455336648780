import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'

import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/it'

// TODO: remove this?
export default {
  BASE_PATH: '${BASE_PATH_ENV_VAR_IM_KEY}',
}

dayjs.extend(utc)
dayjs.extend(isBetween)
dayjs.extend(isoWeek)
dayjs.extend(localizedFormat)
