import { IM, IMLayout, IMStyle, useDimensions, useLanguage } from '@infominds/react-native-components'
import Color from 'color'
import React from 'react'
import { ScrollView, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { FilterType, GroupType, OrderType, PlanDateFilterType } from '../../../contexts/FilterContext'
import { QualityCheckGroupType, QualityCheckOrderType } from '../../../contexts/QualityCheckFilterContext'
import { QualityFilterType, QualityGroupType, QualityOrderType } from '../../../contexts/QualityFilterProvider'
import { SparePartsFilterType, SparePartsOrderType } from '../../../contexts/SparePartsFilterContext'
import { Filter, Group, Order } from '../../../types'
import TimeUtils from '../../../utils/TimeUtils'
import Pressable from '../../Infominds/Pressable'
import TextWithIcon from '../../Infominds/TextWithIcon'
import Tag from '../../Tag'

interface Props {
  enabled: boolean
  activeFilters?: Filter<FilterType>[] | Filter<SparePartsFilterType>[] | Filter<QualityFilterType>[]
  activeGroups?: Group<GroupType>[] | Group<QualityCheckGroupType>[] | Group<QualityGroupType>[]
  activeOrders: Order<OrderType>[] | Order<QualityCheckOrderType>[] | Order<SparePartsOrderType>[] | Order<QualityOrderType>[]
  style?: StyleProp<ViewStyle>
  onNavigate: () => void
  changeFilterStatus: (id: string, emitId?: string) => void
}

export default function CommonFilterDetailHeader({
  enabled,
  activeFilters,
  activeGroups,
  activeOrders,
  style,
  onNavigate,
  changeFilterStatus,
}: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()

  const marginLeft = isSmallDevice ? 4 : 0

  return (
    <>
      <IM.View style={[styles.container, isSmallDevice && IMLayout.flex.f1, style]}>
        <ScrollView horizontal contentContainerStyle={[styles.scrollView, { marginLeft }]} showsHorizontalScrollIndicator={false}>
          {(activeGroups ? activeGroups.length === 0 : true) &&
            (activeFilters ? activeFilters.length === 0 : true) &&
            activeOrders.length === 0 &&
            isSmallDevice && (
              // eslint-disable-next-line react-native/no-inline-styles
              <IM.Text style={{ color: Color(IMStyle.palette.white).darken(0.3).toString(), paddingLeft: 6 }}>{i18n.t('NO_FILTER_ACTIVE')}</IM.Text>
            )}
          {activeGroups?.map(el => {
            return (
              <Tag
                key={el.data.id}
                id={el.data.id}
                name={el.data.name}
                active={el.active}
                isHeader
                onPress={() => changeFilterStatus(el.data.id)}
                icon={['fal', 'layer-group']}
              />
            )
          })}
          {activeOrders.map(el => {
            return (
              <Tag
                key={el.data.id}
                id={el.data.id}
                name={el.data.name}
                active={el.active}
                isHeader
                onPress={() => changeFilterStatus(el.data.id)}
                icon={el.data.icon ?? ['fal', 'arrow-up-arrow-down']}
              />
            )
          })}
          {activeFilters?.map(filter => {
            if (filter.id === FilterType.PlanDate) {
              const from = filter.elements.find(el => el.id === PlanDateFilterType.PlanDateFrom)?.value
              const to = filter.elements.find(el => el.id === PlanDateFilterType.PlanDateTo)?.value
              const value = (from ? TimeUtils.format(from, language) : '') + ' - ' + (to ? TimeUtils.format(to, language) : '')

              return (
                <Tag
                  key={value}
                  id={value}
                  name={value}
                  active={filter.elements.some(el => el.active)}
                  isHeader
                  icon={['fal', 'filter']}
                  onPress={() => changeFilterStatus(filter.id)}
                />
              )
            }

            return filter.elements.map(el => {
              return (
                <Tag
                  key={`${filter.id}-${el.id}`}
                  active={el.active}
                  id={el.id}
                  name={el.description ?? el.id}
                  isHeader
                  onPress={() => changeFilterStatus(filter.id, el.id)}
                  icon={['fal', 'filter']}
                />
              )
            })
          })}
        </ScrollView>
        <Pressable disabled={!enabled} onPress={onNavigate} style={{ marginLeft: IMLayout.horizontalMargin }} hitSlop={{ top: 10, bottom: 8 }}>
          <TextWithIcon alignIcon="right" icon={['fal', 'chevron-right']} color={IMStyle.palette.white} secondary={!enabled}>
            {i18n.t('FILTERS')}
          </TextWithIcon>
        </Pressable>
      </IM.View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  scrollView: {
    marginVertical: 4,
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
