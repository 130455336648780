import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { SectionListRenderItemInfo, TextStyle } from 'react-native'

import { BooleanSelectorValue } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  title: string
  titleFontWeight?: TextStyle['fontWeight']
  value: boolean | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: boolean | undefined) => void
}

export default function BooleanSelector({ value, title, titleFontWeight, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const [selectedValue, setSelectedValue] = useState(value ?? CONSTANTS.noSelectionId)

  const data: BooleanSelectorValue[] = useMemo(
    () => [
      ...(value === undefined
        ? [
            {
              id: CONSTANTS.noSelectionId,
              value: CONSTANTS.noSelectionId,
              description: i18n.t('NO_SELECTION'),
            },
          ]
        : []),
      {
        id: 'false',
        value: false,
        description: i18n.t('NO'),
      },
      {
        id: 'true',
        value: true,
        description: i18n.t('YES'),
      },
    ],
    // No value here. This is decided by the initial value an must not be changed!
    [i18n]
  )

  const render = ({ item: el }: SectionListRenderItemInfo<BooleanSelectorValue, ListSection<BooleanSelectorValue>>, onPress?: () => void) => {
    return <RadioPressable onPress={() => onPress?.()} selected={el.value === selectedValue} text={el.description} />
  }

  const handleOnChange = (newValue: BooleanSelectorValue | undefined) => {
    setSelectedValue(newValue?.value ?? CONSTANTS.noSelectionId)
    onChange(newValue === undefined || newValue.value === CONSTANTS.noSelectionId ? undefined : newValue.value ? true : false)
  }

  return (
    <SelectInput
      data={appUtils.filter(data, search, ['description'])}
      value={data?.find(elem => elem.value === selectedValue)}
      onSearchChange={setSearch}
      title={title}
      screenTitle={title}
      titleFontWeight={titleFontWeight}
      noDataMessage={i18n.t('NO_VALUE_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={val => val.description}
      deferredTimeout={0}
      disableLoadAfterMount
      disableBackdropOpacity
      loading={false}
      fastInputEntries={3}
      {...props}
    />
  )
}
