import { IM, IMLayout, IMStyle, ThemeColorsType } from '@infominds/react-native-components'
import { ListRenderItemInfo } from '@shopify/flash-list'
import React, { memo, ReactNode } from 'react'

type RenderItemProps<T> = ListRenderItemInfo<T> & {
  stickyIndex: number[]
  theme: ThemeColorsType
  renderItem: (item: ListRenderItemInfo<T>) => ReactNode
}

function FlashListRenderItem<T>(props: RenderItemProps<T>) {
  const isLastElementOfSection = props.stickyIndex.includes(props.index + 1)

  if (typeof props.item === 'string') {
    return (
      <IM.View
        style={{
          paddingTop: IMLayout.horizontalMargin,
          paddingBottom: IMLayout.horizontalMargin / 2,
          paddingHorizontal: 2 * IMLayout.horizontalMargin,
          backgroundColor: props.theme.background.default,
        }}>
        <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{props.item}</IM.Text>
      </IM.View>
    )
  }

  return (
    <IM.View style={{ paddingBottom: isLastElementOfSection ? 2 * IMLayout.horizontalMargin : undefined }}>{props.renderItem({ ...props })}</IM.View>
  )
}

export default memo(FlashListRenderItem)
