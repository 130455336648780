import { useDimensions, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import Clipboard from '@react-native-clipboard/clipboard'
import React, { useEffect, useState } from 'react'
import { Animated, Platform } from 'react-native'
import { useResetRecoilState } from 'recoil'

import PressableIcon from '../../components/Infominds/PressableIcon'
import { MasterDetailProvider } from '../../components/MasterDetail/contexts/MasterDetailContext'
import MasterDetail from '../../components/MasterDetail/MasterDetails'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import AccessDataFilterDetailHeader from '../../components/screen/headers/AccessDataFilterDetailHeader'
import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import { DefaultSearchDetailHeaderProps } from '../../components/screen/headers/default/DefaultSearchDetailHeader'
import HeaderWithIconsSync from '../../components/screen/headers/HeaderWithIconsSync'
import { Screen } from '../../components/screen/Screen'
import CONSTANTS from '../../constants/Constants'
import AccessDataFilterContext, { AccessDataFilterProvider } from '../../contexts/AccessDataFilterContext'
import ACSPasswordContext, { ACSPasswordProvider } from '../../contexts/ACSPasswordContext'
import { AccessDataStackScreenProps } from '../../navigation/navigators/common/AccessDataCommonNavigator'
import { ThemeColorExpanded } from '../../types'
import { accessDataSelectedIdAtom } from '../../utils/stateManager'
import AccessDataDetailView from '../../views/accessData/AccessDataDetailView'
import AccessDataListView from '../../views/accessData/AccessDataListView'

export default function AccessDataScreen({ route, navigation }: AccessDataStackScreenProps<'AccessData'>) {
  const { isSmallDevice } = useDimensions()
  const { customerCode } = useAuthentication()
  const { theme } = useTheme<ThemeColorExpanded>()
  const buttonAnimationValue = new Animated.Value(0)
  const resetAccessDataSelectedId = useResetRecoilState(accessDataSelectedIdAtom)

  const [copied, setCopied] = useState(false)

  useEffect(() => {
    copied && setTimeout(() => setCopied(false), CONSTANTS.resetCheckIcon)
  }, [copied])

  const commonSearchProps: DefaultSearchDetailHeaderProps = {
    deferredTimeout: 0,
  }

  return (
    <SearchProvider>
      <AccessDataFilterProvider>
        <AccessDataFilterContext.Consumer>
          {context => {
            const activeFilters = context?.filters.filter(filter => filter.active)
            const activePermissions = context?.permissions.filter(permission => permission.active)

            return (
              <ACSPasswordProvider>
                <ACSPasswordContext.Consumer>
                  {acsContext => {
                    const acsIconDisabled = acsContext === undefined || acsContext.ready === false
                    const opacity = acsIconDisabled ? 0.4 : 1

                    return (
                      <Screen>
                        <Screen.Header
                          goBack={() => {
                            !isSmallDevice && resetAccessDataSelectedId()
                            navigation.goBack()
                          }}>
                          <HeaderWithIconsSync
                            title={route.params.customerName}
                            description={route.params.customerNumber ? route.params.customerNumber.toString() : undefined}
                            icon={route.params.blocked === 'true' ? ['fas', 'user-slash'] : undefined}
                            iconSize={20}
                            iconColor={theme.general.error}
                            onPress={
                              route.params.customerNumber
                                ? () =>
                                    navigation.navigate('AccessDataCustomerCommonStack', {
                                      screen: 'CustomerDetail',
                                      params: {
                                        customerId: route.params.customerId,
                                        customerName: route.params.customerName,
                                        customerNumber: route.params.customerNumber,
                                        blocked: route.params.blocked,
                                        vat: route.params.vat,
                                      },
                                    })
                                : undefined
                            }
                            tabletSearchComponent={<SearchDetailHeaderContainer dividers={['left']} iconPosition="right" {...commonSearchProps} />}
                            tabletFilterComponent={
                              <FilterDetailHeaderContainer>
                                <AccessDataFilterDetailHeader />
                              </FilterDetailHeaderContainer>
                            }
                            highlightFilter={activeFilters?.length !== 0 || activePermissions?.length !== 0}
                            extraIcon={
                              Platform.OS === 'web' &&
                              customerCode === '2222' && (
                                <>
                                  {copied ? (
                                    <PressableIcon
                                      icon={['fal', 'check']}
                                      color={theme.general.info}
                                      disabled
                                      disabledColor={theme.general.info}
                                      size={18}
                                      opacityColor={theme.card.background}
                                    />
                                  ) : (
                                    <PressableIcon
                                      disabled={acsIconDisabled}
                                      icon={['fal', 'file-prescription']}
                                      size={18}
                                      hitSlop={12}
                                      disabledColor={theme.header.main.text.secondary}
                                      color={theme.header.main.text.primary}
                                      style={{ opacity }}
                                      onPress={() => {
                                        acsContext && acsContext.acsPsw && Clipboard.setString(acsContext.acsPsw)
                                        setCopied(true)
                                      }}
                                    />
                                  )}
                                </>
                              )
                            }
                          />
                        </Screen.Header>
                        <Screen.DetailHeader>
                          <SearchDetailHeaderContainer {...commonSearchProps} />
                          <FilterDetailHeaderContainer>
                            <AccessDataFilterDetailHeader />
                          </FilterDetailHeaderContainer>
                        </Screen.DetailHeader>
                        <Screen.Content>
                          <MasterDetailProvider>
                            <MasterDetail>
                              <MasterDetail.Master>
                                <AccessDataListView
                                  buttonAnimationValue={buttonAnimationValue}
                                  customerId={route.params.customerId}
                                  onEdit={(data, id) => navigation.navigate('AccessDataEditOrCreation', { accessData: data, customerId: id })}
                                  onCreate={id => navigation.navigate('AccessDataEditOrCreation', { customerId: id })}
                                />
                              </MasterDetail.Master>
                              <MasterDetail.Detail>
                                <AccessDataDetailView />
                              </MasterDetail.Detail>
                            </MasterDetail>
                          </MasterDetailProvider>
                        </Screen.Content>
                      </Screen>
                    )
                  }}
                </ACSPasswordContext.Consumer>
              </ACSPasswordProvider>
            )
          }}
        </AccessDataFilterContext.Consumer>
      </AccessDataFilterProvider>
    </SearchProvider>
  )
}
