/* eslint-disable react-native/no-inline-styles */

import { IM, IMLayout, IMStyle, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, StyleSheet, useWindowDimensions } from 'react-native'
import CountryFlag from 'react-native-country-flag'

import Pressable from '../components/Infominds/Pressable'
import TextInput from '../components/input/TextInput'
import LoadingIcon from '../components/LoadingIcon'
import { ThemeColorExpanded } from '../types'
import AvoidingViewModal from './AvoidingViewModal'

export type EditDescriptionModalType = { value: string | undefined; title: string; id?: string; flag?: string }

interface Props {
  controller: ModalController<EditDescriptionModalType>
  loading?: boolean
  error?: string
  onSave: (newValue: string | undefined, id: string | undefined) => void
  onClose: VoidFunction
}

export default function EditDescriptionModal({ ...props }: Props) {
  return <>{props.controller.isShown && <EditDescriptionModalInternal {...props} />}</>
}

function EditDescriptionModalInternal({ controller, loading, error, onSave, onClose }: Props) {
  const { i18n } = useLanguage()
  const { height } = useWindowDimensions()
  const { theme } = useTheme<ThemeColorExpanded>()

  const initial = useRef(controller.data?.value)
  const request = useRef(false)
  const [text, setText] = useState(controller.data?.value)

  useEffect(() => {
    if (controller.isShown && loading === false && error === '' && request.current) {
      handleClose()
    }
  }, [controller.isShown, loading, error])

  const loadingIcon = loading && error === ''
  const disabled = initial.current === text || loadingIcon

  if (!controller.data) return <></>

  const handleSave = () => {
    request.current = true
    onSave(text, controller.data?.id)
  }

  const handleClose = () => {
    request.current = false
    onClose()
    controller.close()
  }

  return (
    <AvoidingViewModal
      controller={controller}
      width={10e6}
      title={
        <>
          <IM.Text style={styles.title}>{controller.data.title}</IM.Text>
          {controller.data.flag && <CountryFlag isoCode={controller.data?.flag} size={20} />}
        </>
      }
      titleViewStyle={[IMLayout.flex.row, { justifyContent: 'space-between' }]}>
      <TextInput
        value={text ?? ''}
        onChangeText={val => setText(val === '' ? undefined : val)}
        multiline
        fixMultilineHeight
        numberOfLines={Platform.OS === 'web' ? 30 : 11}
        style={{ maxHeight: height * 0.7 }}
      />
      {error !== '' && <IM.Text style={{ color: theme.general.error, fontSize: IMStyle.typography.fontSizeSmall, paddingTop: 2 }}>{error}</IM.Text>}
      <IM.View style={styles.buttonsContainer} spacing="top">
        <Pressable spacing="right" onPress={handleClose}>
          <IM.Text style={styles.text}>{i18n.t('CANCEL').toUpperCase()}</IM.Text>
        </Pressable>
        <Pressable disabled={disabled} onPress={handleSave}>
          {loadingIcon ? (
            <LoadingIcon />
          ) : (
            <IM.Text secondary={disabled} style={[styles.text, !disabled && { color: theme.general.info }]}>
              {i18n.t('SAVE').toUpperCase()}
            </IM.Text>
          )}
        </Pressable>
      </IM.View>
    </AvoidingViewModal>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: IMStyle.typography.fontSizeMedium + 2,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  text: {
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
