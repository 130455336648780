import { IM, useDidUpdate, useModalController, useTheme } from '@infominds/react-native-components'
import React, { memo, useRef, useState } from 'react'
import { Platform, TextStyle } from 'react-native'
import { formatWithMask } from 'react-native-mask-input'

import useForm from '../../hooks/useForm'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

type TextInputProps = Omit<BaseTextInputProps, 'onChangeText'> &
  Omit<BaseTextInputProviderProps, 'error'> & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    onChangeTime: (value: number | undefined) => void
  }

const convertTimeFromInputFormat = (time: string) => {
  const [h, m] = time.split(':')
  const date = new Date()
  date.setHours(parseInt(h, 10))
  date.setMinutes(parseInt(m, 10))

  return date
}

const convertTimeToInputFormat = (date: Date) => {
  const toRet = `${date.getHours()}:${date.getMinutes()}`
  const split = toRet.split(':')
  let toRetHours = split[0]
  let toRetMinutes = split[1]

  if (toRetMinutes.length === 1) {
    toRetMinutes = `0${date.getMinutes()}`
  }

  if (toRetHours.length === 1) {
    toRetHours = `0${date.getHours()}`
  }

  return toRetHours + ':' + toRetMinutes
}

const TEXT_LENGTH_REFERENCE = 5

const TimeInput = memo(function TimeInput({
  title,
  details,
  titleFontWeight,
  editable = true,
  loading,
  disableFocus,
  onChangeTime,
  ...textInputProps
}: TextInputProps) {
  const { theme } = useTheme()
  const { setError } = useForm()
  const controller = useModalController()

  const [formError, setFormError] = useState(false)
  const [text, setText] = useState<{ value: string | undefined; autoComplete: boolean }>({ value: textInputProps.value, autoComplete: true })
  const prevText = useRef(text.value)

  const { masked } = formatWithMask({
    text: text.value,
    mask: [/\d/, /\d/, ':', /\d/, /\d/],
    maskAutoComplete: text.autoComplete,
  })

  useDidUpdate(() => {
    if (text.value === undefined) return

    if (text.value.length === TEXT_LENGTH_REFERENCE) {
      handleError(false)

      const split = text.value.split(':')
      const hours = parseInt(split[0], 10)
      const minutes = parseInt(split[1], 10)

      onChangeTime(minutes * 60 + 60 * 60 * hours)
    } else if (text.value.length === 0) {
      handleError(false)
      onChangeTime(undefined)
    }
  }, [text.value])

  const handlePickerTime = (newDate: Date) => {
    setText(prev => ({ ...prev, value: convertTimeToInputFormat(newDate) }))
  }

  const handleError = (value: boolean) => {
    setError(value)
    setFormError(value)
  }

  return (
    <>
      <BaseTextInputProvider editable={editable} error={formError} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput
          {...textInputProps}
          value={masked}
          onChangeText={val => {
            let autoComplete = true

            if (prevText.current !== undefined && val.length <= prevText.current.length) {
              autoComplete = false
            }

            const split = val.split(':')

            if (split.length === 2) {
              let minutesToRet = split[1]
              let hoursToRet = split[0]
              const minutes = parseInt(split[1], 10)
              const hours = parseInt(split[0], 10)

              if (hoursToRet.length > 2) {
                prevText.current = val
                return setText({ value: val, autoComplete })
              }

              if (minutes >= 60) {
                minutesToRet = '59'
              }

              if (hours >= 24) {
                hoursToRet = '23'
              }

              prevText.current = val
              return setText({ value: `${hoursToRet}:${minutesToRet}`, autoComplete })
            }

            prevText.current = val
            return setText({ value: val, autoComplete })
          }}
          type="time"
          placeholder="00:00"
          pointerEvents="box-only"
          onBlur={() => {
            if (text.value && text.value.length < TEXT_LENGTH_REFERENCE && text.value.length > 0) {
              handleError(true)
            } else {
              handleError(false)
            }
          }}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} />}
          {Platform.OS !== 'web' && (
            <BaseTextInput.RightIcon>
              <PressableIcon icon={['fal', 'clock']} color={theme.text.detail} size={20} onPress={() => controller.show()} disabled={!editable} />
            </BaseTextInput.RightIcon>
          )}
        </BaseTextInput>
      </BaseTextInputProvider>
      {Platform.OS !== 'web' && (
        <IM.DateTimePicker
          controller={controller}
          date={text.value?.length === TEXT_LENGTH_REFERENCE ? convertTimeFromInputFormat(text.value) : new Date()}
          onChange={handlePickerTime}
          mode="time"
        />
      )}
    </>
  )
})

export default TimeInput
